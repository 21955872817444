import {Button, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import verificationRequest from '../../../../assets/verification-request.svg';
import {StyledImg} from './styles';
import { UserSubscriptionResponse } from '../../../server/types';

interface DialogProps {
  data?: UserSubscriptionResponse
  onCloseClick: () => void;
}

const VerificationRequestDialog = (props: DialogProps) => {
  return (
    <div className="flex flex-col items-center p-4 space-y-4">
      <StyledImg src={verificationRequest} alt="verification-request"></StyledImg>
      <div className="space-y-2">
        <Typography
          label="ID Verification Request Sent!"
          align="center"
          weight="semibold"
          variant="f3"
          color={colorTheme.dark}
        />
        <div className="flex justify-center">
          <Typography
            align="center"
            label="You have successfully sent your ID Verification request."
            weight="normal"
            variant="f1"
            color={colorTheme.darkFaded}
          />
        </div>
      </div>
      <div className="w-full">
        <Button
          label="Close"
          color="primary"
          onClick={props.onCloseClick}
        />
      </div>
    </div>
  );
};

export default VerificationRequestDialog;
