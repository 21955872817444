import {FilterSettingResponse} from '.';
import {Nullable} from '../../types/types';
import {LivingArrangement} from './filter-settings.types';
import { JobHistoryResponse } from './job-offer.types';

export type UserRole = 'homeowner' | 'kazam' | 'admin';

export type UserStatus = 'active' | 'banned' | 'disabled' | 'deleted' | 'suspended';

export type Gender = 'male' | 'female';

export const genderList = ['Male', 'Female'];

export const languageList = ['English', 'Taglish'];

export type VerifiedStatus = 'notVerified' | 'pending' | 'verified' | 'rejected';

export interface LoginInformation {
  mobileNumber: string;
  password: string;
  repeatPassword: string;
  acceptTerms: boolean;
  acceptDataPrivacy: boolean;
  consentMarketing?: boolean;
}

export interface OTP {
  code: string;
}

export interface UploadPhotoReqBody {
  image?: any | null;
}

export interface UploadMediaReqBody {
  file?: any | null;
  channelId: string;
}

export interface PrimaryInformation {
  firstName: string;
  lastName: string;
  birthDate: Date;
  gender: string;
  location: string;
}

export interface PostUserBody {
  mobileNumber: string;
  firebaseUserId: string;
  firebaseRefreshToken: string;
  userRole: UserRole;
  firstName: string;
  lastName: string;
  birthDate: Date;
  gender: string;
  email?: string;
  password: string;
  referralCode?: string;
}

export interface UserResponse {
  userId: string;
  firebaseUserId: string;
  FirebaseCMToken?: string;
  firebaseRefreshToken: string;
  userRole: UserRole;
  userStatus: UserStatus;
  mobileNumber: string;
  firstName: string;
  lastName: string;
  birthDate: Date;
  gender: string;
  email?: string;
  photo: string;
  createdOn: Date;
  receiveMarketingEmails: boolean;
  modifiedOn: Date;
  filterSetting: FilterSettingResponse;
  isBanned: boolean;
  referralCode: string;
  lastAcceptedTerms?: Date;
  lastAcceptedPrivacyPolicy?: Date;
  lastAcceptedChatDisclaimer?: Date;
  isEligibleForTrial: boolean;
  verifiedStatus: VerifiedStatus
  jobHistory?: JobHistoryResponse[]
  personalityMatch?: PersonalityMatch
  personalityMatchScore?: number
}

export interface GetSystemStatisticsResponse {
  allUsers: number;
  activeUsers: number;
  inactiveUsers: number;
  kazamUsers: number;
  homeownerUsers: number;
  activeKazams: number;
  activeHomeowners: number;
  inactiveKazams: number;
  inactiveHomeowners: number;
}

export interface MatchResponse {
  page: number;
  data: UserResponse[];
  tier1LastIndex?: number;
}

export interface MatchQueryParams {
  page: number;
  pageSize?: number;
  loginDate?: string;
  recentResult?: boolean;
}

export interface PutUserProfileBody {
  mobileNumber: string;
  firstName: string;
  lastName: string;
  birthDate: Date;
  gender: string;
  email?: string;
  locationId: string;
  minSalary?: number;
  livingArrangement?: Nullable<LivingArrangement>;
  roles?: string[];
}

export interface PatchUserProfileBody {
  email?: string;
  receiveSearchMatchEmailNotifs: boolean;
  receiveMarketingEmails: boolean;
}

export interface PatchUserProfileFCMTokenBody {
  token: string;
}

export interface PatchUserProfileStatusBody {
  status: UserStatus;
  reason: string;
}

export interface PostIsMobileExisting {
  mobileNumber: string;
}

export interface PostVerifyOtp {
  mobileNumber: string;
  otp: string;
}

export interface PostPasswordResetRequest {
  mobileNumber: string;
}

export interface PutPasswordResetRequest {
  mobileNumber: string;
  password: string;
}

export interface ResetPassword {
  password: string;
  repeatPassword: string;
}

export interface ChangePassword {
  currentPassword: string;
  password: string;
  repeatPassword: string;
}

export interface ChangePasswordBody {
  currentPassword: string;
  newPassword: string;
}


export interface Experience {
    title: string;
    date: Date;
}

export interface ExperienceResponse {
  page: number;
  data: Experience[];
  tier1LastIndex?: number;
}


export interface PatchUserLocationBody {
  locationId: string
}

export interface PersonalityMatch {
  personalityMatchId: string
  answers: string
  createdOn: Date
}
