import {
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';
import {
  GetAbuseReportsResponse,
  PostBanUnbanUserBody,
  GetBannedUsersResponse,
  GetUsersResponse,
  GetAbuseReportersResponse,
  VerifiedStatus,
} from '../types'; 
import { PaginatedUsersQueryParams, PatchUserVerificationStatusBody } from '../types/admin-control.types';


const postBanUser = (reqBody: PostBanUnbanUserBody) => {
  return API.post(ApiEndpointsEnum.POST_BAN_USER, reqBody);
};

const usePostBanUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['post-ban-user'],
    (reqBody: PostBanUnbanUserBody) => postBanUser(reqBody),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('get-banned-users');
        await queryClient.invalidateQueries(['get-abuse-reports']);
        await queryClient.invalidateQueries(['get-users']);
      },
    },
  );
};

const postUnbanUser = (reqBody: PostBanUnbanUserBody) => {
  return API.post(ApiEndpointsEnum.POST_UNBAN_USER, reqBody);
};

const usePostUnbanUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['post-unban-user'],
    (reqBody: PostBanUnbanUserBody) => postUnbanUser(reqBody),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('get-banned-users');
        await queryClient.invalidateQueries(['get-abuse-reports']);
        await queryClient.invalidateQueries(['get-users']);
      },
    },
  );
};

const getBannedUsers = (pageNumber: number, searchQuery: string) => {
  const url =
    ApiEndpointsEnum.GET_BANNED_USERS +
    `?page=${pageNumber}` +
    (searchQuery.trim() ? `&searchQuery=${searchQuery}` : '');
  return API.get<GetBannedUsersResponse>(url);
};

const useGetBannedUsers = (pageNumber: number, searchQuery: string) => {
  return useQuery(['get-banned-users', pageNumber], () =>
    getBannedUsers(pageNumber, searchQuery),
  );
};

const getUsers = (pageNumber: number, searchQuery: string) => {
  const url =
    ApiEndpointsEnum.GET_USERS +
    `?page=${pageNumber}` +
    (searchQuery.trim() ? `&searchQuery=${searchQuery}` : '');
  return API.get<GetUsersResponse>(url);
};

const useGetUsers = (pageNumber: number, searchQuery: string) => {
  return useQuery(['get-users', pageNumber], () =>
    getUsers(pageNumber, searchQuery),
  );
};

const getAbuseReports = (pageNumber: number, searchQuery: string) => {
  const url =
    ApiEndpointsEnum.GET_ABUSE_REPORT +
    `?page=${pageNumber}` +
    (searchQuery.trim() ? `&searchQuery=${searchQuery}` : '');
  return API.get<GetAbuseReportsResponse>(url);
};

const useGetAbuseReports = (pageNumber: number, searchQuery: string) => {
  return useQuery(['get-abuse-reports', pageNumber], () =>
    getAbuseReports(pageNumber, searchQuery),
  );
};

const getAbuseReporters = (userId: string, pageNumber: number) => {
  const url =
    ApiEndpointsEnum.GET_ABUSE_REPORTERS.replace('{userId}', userId) +
    `?page=${pageNumber}`;
  return API.get<GetAbuseReportersResponse>(url);
};

const useGetAbuseReporters = (userId: string, pageNumber: number) => {
  return useQuery(['get-abuse-reporters', userId, pageNumber], () =>
    getAbuseReporters(userId, pageNumber),
  );
};

const postSuspendUser = (reqBody: PostBanUnbanUserBody) => {
  return API.post(ApiEndpointsEnum.POST_SUSPEND_USER, reqBody);
};

const usePostSuspendUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['post-suspend-user'],
    (reqBody: PostBanUnbanUserBody) => postSuspendUser(reqBody),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('get-suspended-users');
        await queryClient.invalidateQueries(['get-abuse-reports']);
        await queryClient.invalidateQueries(['get-users']);
      },
    },
  );
};

const postUnsuspendUser = (reqBody: PostBanUnbanUserBody) => {
  return API.post(ApiEndpointsEnum.POST_UNSUSPEND_USER, reqBody);
};

const usePostUnsuspendUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['post-unsuspend-user'],
    (reqBody: PostBanUnbanUserBody) => postUnsuspendUser(reqBody),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('get-suspended-users');
        await queryClient.invalidateQueries(['get-abuse-reports']);
        await queryClient.invalidateQueries(['get-users']);
      },
    },
  );
};

const getSuspendedUsers = (pageNumber: number, searchQuery: string) => {
  const url =
    ApiEndpointsEnum.GET_SUSPENDED_USERS +
    `?page=${pageNumber}` +
    (searchQuery.trim() ? `&searchQuery=${searchQuery}` : '');
  return API.get<GetBannedUsersResponse>(url);
};

const patchUserVerifiedStatus = (reqBody: PatchUserVerificationStatusBody) => {
  return API.patch(ApiEndpointsEnum.PATCH_USER_VERIFIED_STATUS, reqBody);
};

const usePatchUserVerifiedStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['patch-user-verification-status'],
    (reqBody: PatchUserVerificationStatusBody) => patchUserVerifiedStatus(reqBody),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('get-suspended-users');
        await queryClient.invalidateQueries(['get-abuse-reports']);
        await queryClient.invalidateQueries(['get-users']);
        await queryClient.invalidateQueries([`get-for-verification-users`])
        await queryClient.invalidateQueries([`get-verified-users`])
      },
    },
  );
};

const useGetSuspendedUsers = (pageNumber: number, searchQuery: string) => {
  return useQuery(['get-suspended-users', pageNumber], () =>
    getSuspendedUsers(pageNumber, searchQuery),
  );
};

const getUsersWithVerifiedStatus = (
  status: VerifiedStatus,
  pageNumber: number,
  searchQuery: string
) => {
  const url =
    ApiEndpointsEnum.GET_USERS_WITH_VERIFIED_STATUS
    + `?page=${pageNumber}`
    + (searchQuery.trim() ? `&searchQuery=${searchQuery}` : '')
    + (status ? `&status=${status}` : '');
  return API.get<GetUsersResponse>(url);
};

const useGetForVerificationUsers = (params: PaginatedUsersQueryParams) => {
  return useQuery(['get-for-verification-users', params.page], () =>
    getUsersWithVerifiedStatus('pending', params.page, params.searchQuery ?? ""),
  );
};

const useGetVerifiedUsers = (params: PaginatedUsersQueryParams) => {
  return useQuery(['get-verified-users', params.page], () =>
    getUsersWithVerifiedStatus('verified', params.page, params.searchQuery ?? ""),
  );
};

export {
  useGetBannedUsers,
  useGetUsers,
  useGetAbuseReports,
  useGetAbuseReporters,
  usePostBanUser,
  usePostUnbanUser,
  usePostSuspendUser,
  usePostUnsuspendUser,
  useGetSuspendedUsers,
  useGetForVerificationUsers,
  useGetVerifiedUsers,
  usePatchUserVerifiedStatus
};
