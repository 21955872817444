import * as yup from 'yup';

export const durationSchema = yup.object({
  days: yup
    .number()
    .typeError('Enter a valid number.')
    .test(
      'min',
      'Minimum value is 0',
      value => typeof value === 'number' && value >= 0,
    ),
  hours: yup
    .number()
    .typeError('Enter a valid number.')
    .test(
      'min',
      'Minimum value is 0',
      value => typeof value === 'number' && value >= 0,
    ),
  minutes: yup
    .number()
    .typeError('Enter a valid number.')
    .test(
      'min',
      'Minimum value is 0',
      value => typeof value === 'number' && value >= 0,
    ),
  seconds: yup
    .number()
    .typeError('Enter a valid number.')
    .test(
      'min',
      'Minimum value is 0',
      value => typeof value === 'number' && value >= 0,
    ),
});

export const quantitySchema = yup.object({
  value: yup
    .number()
    .integer('Value must be a whole number.')
    .typeError('Enter a valid number.')
    .test(
      'min',
      'Minimum value is 1',
      value => typeof value === 'number' && value >= 1,
    )
    .test(
      'max',
      'Maximum value is 99999',
      value => typeof value === 'number' && value <= 99999,
    ),
});

export const searchWeightsSchema = yup.object({
  locationWeighValue: yup
    .number()
    .typeError('Enter a valid number.')
    .test(
      'min',
      'Minimum value is 0',
      value => typeof value === 'number' && value >= 0,
    ),
  employmentWeightValue: yup
    .number()
    .typeError('Enter a valid number.')
    .test(
      'min',
      'Minimum value is 0',
      value => typeof value === 'number' && value >= 0,
    ),
  salaryWeightValue: yup
    .number()
    .typeError('Enter a valid number.')
    .test(
      'min',
      'Minimum value is 0',
      value => typeof value === 'number' && value >= 0,
    ),
  roleWeightValue: yup
    .number()
    .typeError('Enter a valid number.')
    .test(
      'min',
      'Minimum value is 0',
      value => typeof value === 'number' && value >= 0,
    ),
  personalityMatchWeightValue: yup
    .number()
    .typeError('Enter a valid number.')
    .test(
      'min',
      'Minimum value is 0',
      value => typeof value === 'number' && value >= 0,
    ),
});

export const postAdSchema = yup.object({
  name: yup.string().trim().required('This field is required.'),
  outboundLink: yup.string().trim().nullable(),
  startDate: yup.date().required().typeError('Enter a valid date'),
  endDate: yup
    .date()
    .required()
    .typeError('Enter a valid date')
    .min(yup.ref('startDate'), "End date can't be before start date."),
  image: yup.string().required('Please upload image.'),
  featured: yup.bool(),
  includeInLoop: yup.bool()
});

export const putAdSchema = yup.object({
  name: yup.string().trim().required('This field is required.'),
  outboundLink: yup.string().trim().nullable(),
  startDate: yup.date().required().typeError('Enter a valid date'),
  endDate: yup
    .date()
    .required()
    .typeError('Enter a valid date')
    .min(yup.ref('startDate'), "End date can't be before start date."),
});

export const postBroadcast = yup.object({
  readOnly: yup.bool().required(),
  userIds: yup.array(),
  fileName: yup.string().trim().required('Upload valid csv file.'),
  title: yup.string().trim().required('This field is required.'),
  message: yup.string().trim().required('This field is required.'),
});

export const postReferalLinkSchema = yup.object({
  name: yup.string().trim().required('This field is required.'),
  code: yup
    .string()
    .trim()
    .required('This field is required.')
    .matches(
      /^\S+$/,
      "Must not have spaces."
    )
    .matches(
      /^[ A-Za-z0-9]*$/,
      "Must be alphanumeric."
    ),
  type: yup.string().trim().required('This field is required.'),
});

export const postSubscriptionSchema = yup.object({
  name: yup.string().trim().required('This field is required.'),
  period: yup
    .number()
    .positive("Must be a positive number.")
    .nullable(true)
    .typeError("Enter a valid number.")
    .transform((_, val) => val ? Number(val) : null),
  amount: yup
    .number()
    .required('This field is required.')
    .positive("Must be a positive number.")
    .typeError('Enter a valid number.')
    .test(
      'min',
      'Minimum value is 20',
      value => typeof value === 'number' && value >= 20,
    ),
  availLimit: yup
    .number()
    .positive("Must be a positive number.")
    .nullable(true)
    .typeError("Enter a valid number.")
    .transform((_, val) => val ? Number(val) : null),
  priority: yup
    .number()
    .required('This field is required.')
    .positive("Must be a positive number.")
    .typeError('Enter a valid number.')
    .test(
      'min',
      'Minimum value is 0',
      value => typeof value === 'number' && value >= 0,
    ),
  startDate: yup
    .date()
    .nullable(true)
    .typeError('Enter a valid date')
    .min(yup.ref('startDate'), "End date can't be before start date.")
    .transform((_, val) => val ?? null),
  endDate: yup
    .date()
    .nullable(true)
    .typeError('Enter a valid date')
    .min(yup.ref('startDate'), "End date can't be before start date.")
    .transform((_, val) => val ?? null),
});


export const postVoucherSchema = yup.object({
  name: yup.string().trim().required('This field is required.'),
  code: yup
  .string()
  .trim()
  .required('This field is required.')
  .matches(
    /^\S+$/,
    "Must not have spaces."
  )
  .matches(
    /^[ A-Za-z0-9]*$/,
    "Must be alphanumeric."
  ),
  discountType: yup.string().trim().nullable(),
  discountValue: yup
    .number()
    .required('This field is required.')
    .positive("Must be a positive number.")
    .typeError('Enter a valid number.'),
  numberOfUses: yup
    .number()
    .positive("Must be a positive number.")
    .nullable(true)
    .typeError("Enter a valid number.")
    .transform((_, val) => val ? Number(val) : null),
  numberOfUsesPerUser: yup
    .number()
    .positive("Must be a positive number.")
    .nullable(true)
    .typeError("Enter a valid number.")
    .transform((_, val) => val ? Number(val) : null),
  startDate: yup
    .date()
    .nullable(true)
    .typeError('Enter a valid date')
    .min(yup.ref('startDate'), "End date can't be before start date.")
    .transform((_, val) => val ?? null),
  endDate: yup
    .date()
    .nullable(true)
    .typeError('Enter a valid date')
    .min(yup.ref('startDate'), "End date can't be before start date.")
    .transform((_, val) => val ?? null),
});