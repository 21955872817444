import {
  Typography,
  Button,
  WizardStep,
  ImageUpload,
} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import logo from '../../../../assets/KAZAM_logo_Transparent.svg';
import {useState} from 'react';
import {UploadPhotoReqBody, UserResponse} from '../../../server/types';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import {useHistory, useLocation} from 'react-router-dom';
import {useUploadProfilePhoto} from '../../../server/react-query';
import {useAuth} from '../../../hooks/useAuth';
import Page from '../../../../core/components/page/Page';
import {localization} from '../../../localization/Localization';
import {useAnalyticsTrackComponent} from '../../../hooks/useAnalyticsTrackComponent';
import {useAnalytics} from '../../../hooks/useAnalytics';

const SignUpLoginInformationPage = (props: any) => {
  useAnalyticsTrackComponent(SignUpLoginInformationPage.name);

  const {systemLogEvent} = useAnalytics();
  const history = useHistory();
  const {updateAuthData, authData} = useAuth();
  const location = useLocation();

  const [photo, setPhoto] = useState<UploadPhotoReqBody>({
    image: null,
  });
  const uploadMutation = useUploadProfilePhoto(photo);

  const handleSave = async () => {
    try {
      if (photo.image) {
        var user = await uploadMutation.mutateAsync();

        let data = authData;
        if (data) {
          data.user = user as UserResponse;
          updateAuthData(data);
        }
      }
      localStorage.removeItem('signup');

      systemLogEvent('complete_registration_with_photo');
      if (location.pathname === RoutePath.EMPLOYER_SIGN_UP) {
        systemLogEvent('homeo_reg_step_5_opt_in');
      } else {
        systemLogEvent('kazam_reg_step_7_opt_in');
      }

      handleNext();
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleSkip = () => {
    localStorage.removeItem('signup');
    if (location.pathname === RoutePath.EMPLOYER_SIGN_UP) {
      systemLogEvent('homeo_reg_step_5_opt_out');
    } else {
      systemLogEvent('kazam_reg_step_7_opt_out');
    }
    handleNext();
  };

  const handleNext = () => {
    location.pathname === RoutePath.EMPLOYER_SIGN_UP
      ? history.replace(RoutePath.DEFAULT_FINDER_SETTINGS)
      : history.replace(RoutePath.PROFILE_SETUP);
  };

  const onSelectPhoto = (file: any | null) => {
    setPhoto({
      image: file,
    });
    console.log(file);
  };

  return (
    <Page hideNavbar>
      <div className="space-y-6">
        <div>
          <WizardStep
            steps={props.totalSteps}
            currentStep={props.currentStep}></WizardStep>
        </div>
        <div className="flex justify-center">
          <img style={{height: '29px'}} src={logo} alt="Logo" />
        </div>
        <div>
          <div>
            <Typography
              label={localization.hdr_su_prof_pic}
              variant="f3"
              align="center"
              color={colorTheme.dark}></Typography>
          </div>
          <div className="pt-1">
            <Typography
              label={localization.desc_su_prof_pic}
              variant="f1"
              align="center"
              color={colorTheme.darkFaded}></Typography>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <ImageUpload onSelectPhoto={onSelectPhoto}></ImageUpload>
        </div>
        <div>
          <div>
            <Button
              label={localization.btn_save_finish}
              color="primary"
              onClick={handleSave}
              isLoading={uploadMutation.isLoading}
            />
          </div>
          <div className="pt-4">
            <Button
              label={localization.btn_skip_finish}
              variant="outlined"
              onClick={handleSkip}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default SignUpLoginInformationPage;
