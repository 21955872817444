import {globalFilterStorageKey} from './chat-filter.util';

const storageKeys = [
  'authData',
  'token',
  'fcmToken',
  'fcmTokenId',
  'signup',
  'chat-page-active-index',
  'isNewKazam',
  'adminData',
  'locations',
  'provinces',
  'homeownerLocation',
  'homeownerLocationName',
  'roles',
  'currentProfile',
  'currentMatchesData',
  'currentSessionTimeStamp',
  'lastLoginDate',
  'referralListPageNumber',
  'currentLocation',
  'signUpCurrentStep',
  'signUpData',
  'filterRoles',
  'personalityTest',
  globalFilterStorageKey,
] as const;

type StorageKey = typeof storageKeys[number];

const get = <T>(itemKey: StorageKey, isJson = false): T | null => {
  let value = localStorage.getItem(itemKey);
  if (value === null || value === undefined || value === '') {
    return null;
  } else {
    if (isJson) {
      return JSON.parse(localStorage.getItem(itemKey) || '') as T;
    } else {
      return (localStorage.getItem(itemKey) as unknown) as T;
    }
  }
};

const save = (itemKey: StorageKey, itemValue: any, isJson = false) => {
  if (isJson) {
    localStorage.setItem(itemKey, JSON.stringify(itemValue));
  } else {
    localStorage.setItem(itemKey, itemValue);
  }
};

const clean = (itemKey?: StorageKey) => {
  if (itemKey) {
    localStorage.removeItem(itemKey);
  } else {
    storageKeys.forEach(s => {
      localStorage.removeItem(s);
    });
  }
};

export const STORAGE = {get, save, clean};
