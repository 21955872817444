import {Icon, PersonalityMatchTag, ProfileStatus, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {KazamRole, UserResponse} from '../../../server/types';
import {
  StyledDefaultProfileView,
  StyledImage,
  StyledProfileOverlay,
} from './styles';
import {useEffect, useState} from 'react';
import {LivingArrangement} from '../../../server/types/filter-settings.types';
import WrappedProfileStatus from '../../../components/wrapped-profile-status/WrappedProfileStatus';
import _ from 'lodash';
import {localization} from '../../../localization/Localization';
import { ProgressiveImage } from '../../../../core/components/progressive-image/ProgressiveImage';
import VerifiedTag from '../../../../core/components/verified-tag/VerifiedTag';
import { Router, useHistory } from 'react-router-dom';
import { RoutePath } from '../../../navigation/config/RouteConfig';
import GenericConfirmationDialog from '../../dialog-content/generic-dialog/GenericConfirmationDialog';
import { useDialog } from '../../../../core/components/dialog';

interface HomeownerProfileViewProps {
  profile?: UserResponse;
  className?: string;
  adminView?: boolean;
  myProfile?: boolean
}

const HomeownerProfileView = (props: HomeownerProfileViewProps) => {
  const {profile} = props;
  const history = useHistory()
  const {presentDialog, dismissDialog} = useDialog();

  const isVerified = profile?.verifiedStatus === 'verified'
  const showPersonalityMatch = !!profile?.personalityMatch && !props?.myProfile
  const personalityMatchScore = profile?.personalityMatchScore
    ? `${Math.round(profile?.personalityMatchScore * 100)}`
    : '**'
  
  const [lookingForLabel, setLookingForLabel] = useState<string>('');
  const [locationLabel, setLocationLabel] = useState<string>('');
  const [livingArrangementLabel, setLivingArrangementLabel] = useState<string>(
    '',
  );
  const [introLabel, setIntroLabel] = useState<string>('');

  useEffect(() => {
    if (profile) {
      getProfileDescription(profile);
    }
  }, [profile]);

  const getProfileDescription = (profile: UserResponse) => {
    const newLookingForLabel = getRoleDescription(
      profile.filterSetting.kazamRoles,
    );
    setLookingForLabel(newLookingForLabel);

    let newLocationLabel = profile.filterSetting.location?.text || '';
    if (profile.filterSetting.location
      && profile.filterSetting.location.text !== profile.filterSetting.location.adminName) {
        newLocationLabel = `${newLocationLabel}, ${profile.filterSetting.location.adminName}`
      }
    setLocationLabel(newLocationLabel);

    const newLivingArrangementLabel = getLivingArrangementLabel(
      profile.filterSetting.livingArrangement,
    );
    setLivingArrangementLabel(newLivingArrangementLabel);

    const newIntroLabel = `${localization.ho_pr_i_am} ${profile.firstName} ${
      localization.ho_pr_from
    } ${newLocationLabel}. ${
      localization.ho_pr_looking
    } ${newLookingForLabel} ${
      localization.ho_pr_with
    } ${newLivingArrangementLabel.toLowerCase()} ${localization.ho_pr_setup}.`;
    setIntroLabel(newIntroLabel);
  };

  const getRoleDescription = (roles: KazamRole[]) => {
    var roleCopy = _.cloneDeep(roles);
    if (roleCopy.length === 2) {
      return `${roleCopy
        .map(kr => kr.role.text)
        .join(` ${localization.ho_pr_and} `)}`;
    } else if (roleCopy.length > 2) {
      const lastRole = roleCopy.pop();
      const commaRoles = roleCopy.map(kr => kr.role.text).join(', ');
      return `${commaRoles} ${localization.ho_pr_and} ${lastRole?.role.text}`;
    } else {
      return `${roleCopy[0].role.text}`;
    }
  };

  const getLivingArrangementLabel = (la: LivingArrangement) => {
    switch (la) {
      case 'stayIn':
        return 'Stay-In';
      case 'stayOut':
        return 'Stay-Out';
      default:
        return `Stay-In ${localization.ho_pr_or} Stay-Out`;
    }
  };

  const handlePersonalityMatchTagClick = () => {
    if (personalityMatchScore === '**') {
      presentDialog({
        headerText: '',
        content: (
          <GenericConfirmationDialog
            headerText="Do your personalities match?"
            alignHeader='center'
            desc="Ang user na ito ay kumuha ng personality matching test. 
              Para malaman kung gaano katugma ang inyong personality, 
              maaari ring sagutin ang Personality test."
            rightButtonText="Take Personality Test"
            rightButtonColor='danger'
            onRightButtonAction={() => {
              dismissDialog()
              history.push(RoutePath.TAKE_PERSONALITY_TEST)
            }}
          />
        ),
        enableBackdropDismiss: false,
        hideClose: false
      });
    } else {
      history.push(RoutePath.TAKE_PERSONALITY_TEST)
    }
  }

  return (
    <div className={['space-y-4', props.className].join(' ')}>
      <StyledDefaultProfileView className="flex items-center justify-center w-full">
        {profile && profile.photo ? (
          // <StyledImage key={profile.photo} src={profile.photo}></StyledImage>
          <ProgressiveImage key={profile.photo} imgSrc={profile.photo}/>
        ) : (
          <Icon name="user" size={215} color={colorTheme.lightGray}></Icon>
        )}

        <StyledProfileOverlay className="flex items-end">
          <div className="space-y-2 flex1">
            <div>
              <div className='flex items-center space-x-1'>
                <Typography
                  className="overflow"
                  label={
                    props.profile?.firstName
                      ? props.profile?.firstName
                      : 'First name'
                  }
                  variant="f3"
                  weight="semibold"
                  color={colorTheme.secondary}
                />

                {isVerified && <VerifiedTag />}
                {showPersonalityMatch && <PersonalityMatchTag />}
              </div>
              <Typography
                label={locationLabel}
                variant="f2"
                weight="normal"
                color="white"
              />
              {profile?.userId ? (
                <WrappedProfileStatus userId={profile.userId} />
              ) : (
                <ProfileStatus status={'online'} />
              )}
            </div>
            <div>
              <Typography
                label={localization.ho_pr_looking_for}
                variant="f1"
                weight="semibold"
                color={colorTheme.secondary}
              />
              <Typography
                label={`${lookingForLabel} ● ${livingArrangementLabel}`}
                variant="f1"
                weight="normal"
                color={colorTheme.light}
              />
            </div>
          </div>
        </StyledProfileOverlay>
      </StyledDefaultProfileView>
      
      <div className='flex gap-2 flex-wrap'>
        {isVerified &&
          <VerifiedTag
            type='verifiedText'
            onClickVerifiedText={() => !props?.adminView && history.push(RoutePath.GET_VERIFIED)}
          />
        }

        {showPersonalityMatch && 
          <PersonalityMatchTag
            type='full'
            score={personalityMatchScore}
            onClick={handlePersonalityMatchTagClick}
          />
        }
      </div>

      <div>
        <Typography
          label={introLabel}
          variant="f1"
          weight="semibold"
          color={colorTheme.darkFaded}
        />
      </div>
    </div>
  );
};

export default HomeownerProfileView;
