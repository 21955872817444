import Page from '../../../core/components/page/Page';
import {Button, Typography} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import verification from '../../../assets/verification.svg';
import {StyledDesc, StyledImg} from './styled';
import {useAuth} from '../../hooks/useAuth';
import { useDialog } from '../../../core/components/dialog';
import { useGetMyProfile, usePatchVerificationRequest } from '../../server/react-query';
import { useToast } from '../../../core/components/toast';
import VerificationRequestDialog from '../../views/dialog-content/verification-request-dialog/VerificationRequestDialog';
import { ButtonColor } from '../../../core/components/button';
import VerifiedTag from '../../../core/components/verified-tag/VerifiedTag';
import { useEffect, useState } from 'react';
import { VerifiedStatus } from '../../server/types';

const VerificationPage = () => {
  const { data, isFetching, refetch } = useGetMyProfile();
  const { presentDialog, dismissDialog } = useDialog();
  const { presentToast } = useToast();

  const [status, setStatus] = useState<VerifiedStatus>('notVerified')

  useEffect(() => {
    if (data && data?.verifiedStatus) {
      setStatus(data.verifiedStatus)
    }
  }, [data])

  const patchVerificationRequest = usePatchVerificationRequest()
  const onRequestVerification = async () => {
    try {
      if (['notVerified', 'rejected'].includes(status)) {
        await patchVerificationRequest.mutateAsync();
      }
      refetch()
      showSuccessDialog()
    } catch (e) {
      console.log(e);
      presentToast({
        message: `Unexpected error was occurred.`,
        variant: 'error',
        position: 'bottom',
      });
    }
  }

  const getColor = (): ButtonColor => {
    let color: ButtonColor = 'primary'
    if (status === 'pending')
      color = 'darkFaded'

    return color
  }

  const showSuccessDialog = () => {
    presentDialog({
      headerText: '',
      content: (
        <VerificationRequestDialog onCloseClick={dismissDialog} />
      ),
      enableBackdropDismiss: false
    });
  }

  const getDesc = () => {
    let desc = ''
    if (data
      && data?.verifiedStatus) {
      if (['notVerified', 'rejected'].includes(data.verifiedStatus))
        return `Stand out from other users on the platform by getting your own identity verified! \
          Before you click the button below, <a href="/edit-profile">update your profile</a> \
          so that your full name, birthdate, and photo clearly match your valid government ID.`

      if (data.verifiedStatus === 'pending')
        return `Your ID Verification request has been sent! Stand by for further instructions \
          from the official kazam account in Chats.  While waiting for verification, remember \
          that <a href="/edit-profile">your user profile</a> details (full name, birthdate, and photo) \
          should clearly match your valid government ID.`
    }

    return desc
  }

  return (
    <Page
      showBackButton
      showLoading={isFetching}>
      <div className="space-y-4">
        <div>
          <StyledImg src={verification} alt="verification"></StyledImg>
        </div>

        <div className='flex space-x-1 justify-center items-center'>
          <VerifiedTag type='verifiedText' />
          <Typography
            label="at kazam"
            variant="f3"
            weight="semibold"
            align="center"
            color={colorTheme.dark}
          />
        </div>

        <Typography
          label="Users with the ID Verified tag were able to present valid government IDs that match their profile details (name, photo, birthdate).
          ID Verification does not guarantee trustworthiness or level of job experience, so remember to do your due diligence when dealing with other users on the platform."
          variant="f1"
          color={colorTheme.darkFaded}
          align='center'
        />

        <StyledDesc
          dangerouslySetInnerHTML={{
            __html: getDesc(),
          }}
        />

        {status === 'verified' && 
          <div className='flex flex-col items-center space-y-1'>
            <Typography
              label="Your profile is"
              variant="f1"
              color={colorTheme.darkFaded}
              align='center'
            />
            <VerifiedTag type='verifiedText' />
            <Typography
              label="To maintain your status, remember to keep your full name, birthdate, and photo unchanged. Changing those details will reset your ID Verified status.​"
              variant="f1"
              color={colorTheme.darkFaded}
              align='center'
            />
          </div>
        }

        {data?.verifiedStatus !== 'verified' && 
          <Button
            label={data?.verifiedStatus === 'pending' ? 'Pending Verification' : 'Request Verification'}
            color={getColor()}
            isLoading={patchVerificationRequest.isLoading}
            onClick={onRequestVerification}
            disabled={data?.verifiedStatus === 'pending'}
          />
        }
      </div>
    </Page>
  );
};

export default VerificationPage;
