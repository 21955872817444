import React, {useEffect, useState} from 'react';
import {ChatResponse, ChatMemberResponse} from '../../../server/types';
import {StyledGenericChatItem} from './styled';
import {Icon, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {toNumberWithCommas} from '../../../utils/decimal.util';
import {useAuth} from '../../../hooks/useAuth';
import UserOnlineStatus from '../../../components/online-status/UserOnlineStatus';
import LastOnlineTime from '../../../components/online-status/LastOnlineTime';
import {localization} from '../../../localization/Localization';
import VerifiedTag from '../../../../core/components/verified-tag';
interface ChatItemProps {
  data: ChatResponse;
  onClick?: (_: ChatResponse) => void;
  isUnread?: boolean;
}

const ChatItem = (props: ChatItemProps) => {
  const [lookingFor, setLookingFor] = useState<string>('');
  const [salary, setSalary] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [lastMessage, setLastMessage] = useState<string>('');
  const [isKazam, setKazam] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  const {authData} = useAuth();
  const {members, contents} = props.data;
  const {user} = members.find(
    m => m.user.userRole !== 'admin',
  ) as ChatMemberResponse; // Won't be null

  useEffect(() => {
    setKazam(user.userRole === 'kazam');

    var lookingFor = user.filterSetting.kazamRoles
      .map(kr => kr.role.text)
      .join(', ');
    setLookingFor(
      user.userRole === 'kazam'
        ? lookingFor
        : `${localization.ho_pr_looking_for} ${lookingFor}`,
    );

    setLocation(
      user.filterSetting.location?.text
        ? user.filterSetting.location?.text
        : '',
    );

    const dataSalary = `₱ ${toNumberWithCommas(
      user.filterSetting.salary || 0,
    )} ${localization.ho_pr_per_mo}`;
    setSalary(dataSalary);

    setIsDisabled(user.userStatus === 'disabled');
    setIsDeleted(user.userStatus === 'deleted');
  }, [user]);

  useEffect(() => {
    if (contents && contents.length > 0) {
      var content = contents[0];

      if (content.media.length > 0) {
        setLastMessage('Sent an attachment.');
      } else {
        if (contents[0].content === 'KAZAM_SYSTEM_VIDEO_CALL') {
          setLastMessage('Video call notification');
        } else if (contents[0].content === 'KAZAM_SYSTEM_VOICE_CALL') {
          setLastMessage('Voice call notification');
        } else {
          setLastMessage(contents[0].content);
        }
      }
    } else {
      // no message yet
      setLastMessage('[No message yet]');
    }
  }, [contents]);

  const itemClickedHandler = () => {
    props.onClick && props.onClick(props.data);
  };

  return (
    <StyledGenericChatItem
      isHomeowner={authData?.user.userRole === 'homeowner'}
      onClick={itemClickedHandler}>
      <div className="flex flex-row w-full items-center space-x-2">
        <div className="profile-picture">
          {isDeleted ? (
            <div className="disabled-avatar flex justify-center items-center">
              <Icon name="user" color={colorTheme.white}></Icon>
            </div>
          ) : (
            <div className="avatar flex justify-center items-center">
              {user.photo ? (
                <img src={user.photo} alt="profile" />
              ) : (
                <Icon name="user" color={colorTheme.white}></Icon>
              )}
              <div className="online-status flex justify-center items-center">
                <UserOnlineStatus
                  disabledUser={isDisabled}
                  bannedUser={user.isBanned}
                  userId={user.userId}
                  width={10}
                  height={10}
                />
              </div>
            </div>
          )}
        </div>

        <div className="flex justify-between items-center space-x-2 w-full flex1">
          {isDeleted || user.isBanned ? (
            <div className="flex flex-col w-full flex1">
              <Typography
                label={isDeleted ? "Kazam User" : user.firstName}
                variant="f2"
                weight="semibold"
                color={colorTheme.dark}
              />
              <Typography
                label={isDeleted ? "Deleted account" : "This user has been banned"}
                variant="f1"
                color={colorTheme.darkFaded}
              />
            </div>
          ) : (
            <div className="flex flex-col w-full flex1">
              <div className='flex items-center space-x-1'>
                <Typography
                  label={user.firstName}
                  variant="f2"
                  weight="semibold"
                  color={colorTheme.dark}
                />
                {user?.verifiedStatus === 'verified' && <VerifiedTag />}
              </div>
              <div className="flex flex-row">
                <div className="overflow">
                  <Typography
                    label={isKazam ? lookingFor : location}
                    variant="f1"
                    weight="semibold"
                    color={
                      props.isUnread ? colorTheme.dark : colorTheme.darkFaded
                    }
                    singleLine
                  />
                </div>
                <Typography
                  className="bullet"
                  label="•"
                  variant="f1"
                  weight="semibold"
                  color={colorTheme.lightGray}
                />
                <div className="overflow">
                  <Typography
                    label={isKazam ? salary : lookingFor}
                    variant="f1"
                    weight="semibold"
                    color={
                      props.isUnread ? colorTheme.dark : colorTheme.darkFaded
                    }
                    singleLine
                  />
                </div>
              </div>

              <div className="flex flex-row">
                <LastOnlineTime
                  userId={user.userId}
                  variant="f1"
                  weight={props.isUnread ? "semibold" : "normal"}
                  color={
                    props.isUnread ? colorTheme.dark : colorTheme.darkFaded
                  }
                />
                <Typography
                  className="bullet"
                  label="•"
                  variant="f1"
                  weight="semibold"
                  color={colorTheme.lightGray}
                />
                <div className="overflow">
                  <Typography
                    label={lastMessage}
                    variant="f1"
                    weight={props.isUnread ? "semibold" : "normal"}
                    color={
                      props.isUnread ? colorTheme.dark : colorTheme.darkFaded
                    }
                    singleLine
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </StyledGenericChatItem>
  );
};

export default ChatItem;
