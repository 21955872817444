import Page from '../../../../core/components/page/Page';
import KazamProfileView from '../../../views/cards/kazam-profile/KazamProfileView';
import {Button, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {useGetMyProfile} from '../../../server/react-query';
import {useHistory} from 'react-router-dom';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import {localization} from '../../../localization/Localization';

const ProfileSetupPage = () => {
  const history = useHistory();
  const {data, isFetching} = useGetMyProfile();

  const editProfile = async () => {
    history.push(RoutePath.EDIT_PROFILE_SETUP);
  };

  const seeEmployers = async () => {
    localStorage.setItem('isNewKazam', 'true');
    // history.replace(RoutePath.MATCHES_LANDING);
    const path = RoutePath.TAKE_PERSONALITY_TEST + '?signUp=true'
    history.replace(path);
  };

  return (
    <Page
      showLoading={isFetching}
      showRightIcon
      rightIconName="edit"
      onRightActionClick={editProfile}>
      <div className="space-y-4">
        <Typography
          label={localization.hdr_my_profile}
          variant="f3"
          weight="semibold"
          align="center"
          color={colorTheme.dark}
        />
        <KazamProfileView
          profile={data}
          myProfile
        />
        <Button
          label={localization.btn_see_matched_ho}
          color="primary"
          onClick={seeEmployers}></Button>
      </div>
    </Page>
  );
};

export default ProfileSetupPage;
