import React, {useEffect, useState} from 'react';
import {StyledMatchingLandingPage} from './styled';
import {Icon} from '../../../core/components';
import {useHistory, useLocation} from 'react-router-dom';
import {RoutePath} from '../../navigation/config/RouteConfig';
import {
  ChatResponse,
  KazamException,
  PatchUserProfileStatusBody,
  PostFavoriteBody,
  PostHideBody,
  PostKawayBody,
  TagResponse,
  UserResponse,
} from '../../server/types';
import {useAuth} from '../../hooks/useAuth';
import HomeownerProfileView from '../../views/cards/homeowner-profile/HomeownerProfileView';
import {colorTheme} from '../../../core/configs';
import {
  getFeaturedSplashAd,
  getPrivacyPolicy,
  getRandomSplashAd,
  getTaC,
  useAcceptPrivacyPolicy,
  useAcceptTerms,
  useDeleteFavorite,
  useDeleteHide,
  useGetAdminControl,
  useGetConversation,
  useGetFavorites,
  useGetHides,
  useGetKaways,
  usePatchUserProfileStatus,
  usePostConversation,
  usePostFavorite,
  usePostHide,
  usePostKaway,
} from '../../server/react-query';
import KazamProfileView from '../../views/cards/kazam-profile/KazamProfileView';
import {useDialog} from '../../../core/components/dialog';
import KazamKawayDialogContentView from '../../views/dialog-content/kazam-kaway/KazamKawayDialogContentView';
import SearchEndResultDialogContentView from '../../views/dialog-content/search-end-result/SearchEndResultDialogContentView';
import {useToast} from '../../../core/components/toast';
import NoResultView from '../../views/cards/no-result/NoResultView';
import Page from '../../../core/components/page/Page';
import FilterSettingsView from '../../views/sticky-cards/filter-settings/FilterSettingsView';
import KazamProfileStickyView from '../../views/sticky-cards/kazam-profile/KazamProfileStickyView';
import noMatchIllustration from '../../../assets/no_match.svg';
import {isMobile} from 'react-device-detect';
import {useMatches} from '../../hooks/useMatches';
import MessageBadge from '../../components/message-badge/MessageBadge';
import NotificationBadge from '../../components/notification-badge/NotificationBadge';
import {localization} from '../../localization/Localization';
import {useQuery} from 'react-query';
import {AdvertisementResponse, QuantityContent} from '../../server/types/admin-control.types';
import SplashAd from '../../views/ads/splash-ad/SplashAd';
import {useAnalytics} from '../../hooks/useAnalytics';
import {getAdsFrequency, getPaywall} from '../../utils/admin.util';
import ReadRequiredDocumentDialog from '../../views/dialog-content/read-required-document-dialog/ReadRequiredDocumentDialog';
import GenericConfirmationDialog from '../../views/dialog-content/generic-dialog/GenericConfirmationDialog';
import GetAppCard from '../../views/sticky-cards/get-app/GetAppCard';
import { getAppBannerUrl, isUsingBrowser } from '../../utils/random.util';
import FetchingProfilesView from '../../views/dialog-content/fetching-profiles/FetchingProfilesView';
import TrialSubscriptionDialogContentView from '../../views/dialog-content/paywall/trial-subscription-dialog/TrialSubscripionDialogContentView';
import { Browser } from '@capacitor/browser';
import SubscriptionsDialogContentView from '../../views/dialog-content/paywall/subscriptions-dialog/SubscriptionsDialogContentView';
import { useGetActiveSubscription } from '../../server/react-query/useUserSubscription';
import { useGetActiveTrialSubscription } from '../../server/react-query/useTrialSubscription';
import { featuredAdIsSeenMoreThanADay } from '../../utils/date.util';
import { JobHistoryDetails } from '../../server/types/job-offer.types';
import { getJobHistoryDetails } from '../../utils/job-offer.util';
import format from 'date-fns/format';

interface MatchingLandingPageProps {
  showUpdate?: boolean;
}

const MatchingLandingPage = (props: MatchingLandingPageProps) => {
  const history = useHistory();
  const {authData, updateAuthData} = useAuth();
  const {presentDialog, dismissDialog} = useDialog();
  const {presentToast} = useToast();
  const {systemLogEvent} = useAnalytics();

  const location = useLocation();

  const [isHomeowner, setIsHomeowner] = useState<boolean>(false);
  const [shownData, setShownData] = useState<UserResponse | undefined>(
    undefined,
  );
  const [favorites, setFavorites] = useState<TagResponse[]>([]);
  const [hides, setHides] = useState<TagResponse[]>([]);
  const [conversations, setConversations] = useState<ChatResponse[]>([]);
  const [kaways, setKaways] = useState<TagResponse[]>([]);
  const [shownDataIsFavorite, setShownDataIsFavorite] = useState<boolean>(
    false,
  );
  const [shownDataIsHidden, setShownDataIsHidden] = useState<boolean>(false);
  const [shownDataIsWaved, setShownDataIsWaved] = useState<boolean>(false);
  const [jobHistoryDetails, setJobHistoryDetails] = useState<JobHistoryDetails>();
  const [existingConversation, setExistingConversation] = useState<
    ChatResponse | undefined
  >(undefined);
  const [toHide, setToHide] = useState<UserResponse | undefined>(undefined);
  const [nextSplashCounter, setNextSplashCounter] = useState<number>(0);
  const [adsFrequency, setAdsFrequency] = useState<number>(3);
  const [getTerms, setGetTerms] = useState<boolean>(false);
  const [getDataPrivacy, setGetDataPrivacy] = useState<boolean>(false);
  const [showGetAppBanner, setShowGetAppBanner] = useState<boolean>(false);
  const [appUrl, setAppUrl] = useState<string>('');
  const [getFeaturedAd, setGetFeaturedAd] = useState<boolean>(false);
  
  const paywall = getPaywall();
    
  const postFavorite = usePostFavorite({
    favoriteUserId: shownData?.userId || '',
  } as PostFavoriteBody);
  const postHide = usePostHide({
    hideUserId: shownData?.userId || '',
  } as PostHideBody);
  const postKaway = usePostKaway({
    kawayUserId: shownData?.userId || '',
  } as PostKawayBody);

  const {data: hookDataFavorites} = useGetFavorites();
  const {data: hookDataHides} = useGetHides();
  const {data: hookDataConversations} = useGetConversation();
  const {data: hookDataKaways} = useGetKaways();
  const {
    data: userSubscription,
    isFetching: fetchingSubscription
  } = useGetActiveSubscription(true);
  const {data: userTrialSubscription} = useGetActiveTrialSubscription();
  const {data: adsFrequencyData} = useGetAdminControl('adsFrequency');

  const {data: adData, refetch: refetchGetSplashAd, remove} = useQuery(
    ['get-random-splash-ad'],
    getRandomSplashAd,
    {
      enabled: nextSplashCounter === adsFrequency,
    },
  );

  const {data: featuredAd, remove: removeFeatured} = useQuery(
    ['get-featured-splash-ad'],
    getFeaturedSplashAd,
    {
      enabled: getFeaturedAd,
    },
  );

  const {
    data: termsAndCondition,
    refetch: refetchTerms,
    remove: removeTerms,
  } = useQuery(['get-tac'], getTaC, {
    enabled: getTerms,
  });

  const {
    data: privacyPolicy,
    refetch: refetchPrivacyPolicy,
    remove: removePrivacyPolicy,
  } = useQuery(['get-data-privacy'], getPrivacyPolicy, {
    enabled: getDataPrivacy,
  });

  const {
    data: matchData,
    prev: previousMatch,
    next: nextMatch,
    requery: requeryMatch,
    refetchMatchWithId: refetchMatchWithId,
    isFetching,
    isFetchingNext,
    isFetchingImages,
    hasPrev,
    hasNext,
    isLast,
    excludeFromMatches,
  } = useMatches();

  useEffect(() => {
    if (!props.showUpdate) {
      if (isFetchingImages === undefined) return
      if(isFetchingImages) {
        showFetchingMatchDialog();
      } else {
        dismissDialog();
        setGetFeaturedAd(featuredAdIsSeenMoreThanADay())
      }
    }
    
  }, [isFetchingImages]);

  useEffect(() => {
    if (adsFrequencyData) {
      var frequency = JSON.parse(
        adsFrequencyData.content.toLowerCase(),
      ) as QuantityContent;
      if (frequency && frequency?.value) {
        setAdsFrequency(parseInt(frequency.value.toString()));
      }
    }
  }, [adsFrequencyData]);

  useEffect(() => {
    setShownData(matchData);
    setJobHistoryDetails(getJobHistoryDetails(
      matchData?.jobHistory, authData?.user))
  }, [matchData]);

  useEffect(() => {
    if (hookDataFavorites) {
      systemLogEvent('number_of_favorites', {
        value: hookDataFavorites.length,
      });

      setFavorites(hookDataFavorites.map(fav => ({...fav})));
    }
  }, [hookDataFavorites]);

  useEffect(() => {
    if (hookDataHides) {
      setHides(hookDataHides.map(hide => ({...hide})));
    }
  }, [hookDataHides]);

  useEffect(() => {
    if (hookDataConversations) {
      setConversations(hookDataConversations.map(conv => ({...conv})));
    }
  }, [hookDataConversations]);

  useEffect(() => {
    if (hookDataKaways) {
      systemLogEvent('number_of_waves', {
        value: hookDataKaways.length,
      });

      setKaways(hookDataKaways.map(i => ({...i})));
    }
  }, [hookDataKaways]);

  useEffect(() => {
    if (shownData && kaways) {
      setShownDataIsWaved(!!kaways.find(i => i.tagUserId === shownData.userId));
    }
  }, [shownData, kaways]);

  useEffect(() => {
    if (shownData && conversations) {
      const existingConversation = conversations.find(
        c => !!c.members.find(m => m.userId === shownData.userId),
      );
      setExistingConversation(existingConversation);
    }
  }, [shownData, conversations]);

  useEffect(() => {
    if (shownData) {
      setShownDataIsFavorite(
        !!favorites.find(fav => fav.tagUserId === shownData.userId),
      );
    }
  }, [favorites, shownData]);

  useEffect(() => {
    if (shownData) {
      setShownDataIsHidden(
        !!hides.find(hide => hide.tagUserId === shownData.userId),
      );
    }
  }, [hides, shownData]);

  useEffect(() => {
    if (localStorage.getItem('isNewKazam')) {
      showNewKazamDialog();
    }

    const appUrl = getAppBannerUrl();
    setAppUrl(appUrl);
    if (appUrl) {
      setShowGetAppBanner(true);
    } else {
      setShowGetAppBanner(false);
    }

    var frequency = getAdsFrequency();
    if (frequency && frequency.value) {
      setAdsFrequency(parseInt(frequency.value.toString()));
    }
  }, []);

  useEffect(() => {
    if (termsAndCondition && getTerms) {
      showTerms();
    }
  }, [termsAndCondition]);

  useEffect(() => {
    if (privacyPolicy && getDataPrivacy) {
      showPrivacyPolicy();
    }
  }, [privacyPolicy]);

  useEffect(() => {
    if (location.search.includes("userId")) {
      var userId = location.search.split("=")[1];
      if (userId) {
        refetchMatchWithId(userId);
        history.replace({
          search: "",
        })
      }
    }
  }, [location.search]);

  const showTerms = () => {
    presentDialog({
      headerText: '',
      content: (
        <ReadRequiredDocumentDialog
          headerText={'Terms and Conditions'}
          content={termsAndCondition ? termsAndCondition.content : ''}
          disagreeButtonText={localization.btn_disagree}
          agreeButtonText={localization.btn_agree}
          onDisagreeButtonAction={() => {
            onProceedDisable();
          }}
          onAgreeButtonAction={() => {
            acceptTerms();
          }}
        />
      ),
      enableBackdropDismiss: false,
      hideClose: true,
      fullWidth: true,
    });
  };

  const acceptTermsMutation = useAcceptTerms();
  const acceptTerms = async () => {
    var user = await acceptTermsMutation.mutateAsync();
    if (user) {
      let data = authData;
      if (data) {
        data.user = user;
        updateAuthData(data);
      }
    }
    if (
      authData &&
      authData.user &&
      !authData?.user.lastAcceptedPrivacyPolicy
    ) {
      setGetDataPrivacy(true);
    }
    dismissDialog();
  };

  const showPrivacyPolicy = () => {
    presentDialog({
      headerText: '',
      content: (
        <ReadRequiredDocumentDialog
          headerText={'Data Privacy Policy'}
          content={privacyPolicy ? privacyPolicy.content : ''}
          disagreeButtonText={'Disagree'}
          agreeButtonText={'Agree'}
          onDisagreeButtonAction={() => {
            onProceedDisable();
          }}
          onAgreeButtonAction={() => {
            acceptPrivacyPolicy();
          }}
        />
      ),
      enableBackdropDismiss: false,
      hideClose: true,
      fullWidth: true,
    });
  };

  const acceptPrivacyPolicyMutation = useAcceptPrivacyPolicy();
  const acceptPrivacyPolicy = async () => {
    var user = await acceptPrivacyPolicyMutation.mutateAsync();
    if (user) {
      let data = authData;
      if (data) {
        data.user = user;
        updateAuthData(data);
      }
    }
    dismissDialog();
  };

  const patchUserProfileStatus = usePatchUserProfileStatus();
  const onProceedDisable = async () => {
    try {
      var body: PatchUserProfileStatusBody = {
        status: 'disabled',
        reason: '',
      };
      var user = await patchUserProfileStatus.mutateAsync(body);

      let data = authData;
      if (data) {
        data.user = user;
        updateAuthData(data);
      }
      dismissDialog();
      history.replace(RoutePath.DISABLED_LANDING);
    } catch (error) {
      console.log(error);
    }
  };

  const showNewKazamDialog = () => {
    presentDialog({
      headerText: '',
      content: <KazamKawayDialogContentView onContinueClick={dismissDialog} />,
      enableBackdropDismiss: false,
    });
    localStorage.removeItem('isNewKazam');
  };

  const showEndOfResultDialog = () => {
    presentDialog({
      headerText: '',
      content: (
        <SearchEndResultDialogContentView
          onContinueClick={() => {
            requeryMatch();
            dismissDialog();
          }}
        />
      ),
      enableBackdropDismiss: false,
    });
  };

  useEffect(() => {
    if (adData) {
      remove();
      showSplash(adData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adData]);

  useEffect(() => {
    if (featuredAd
      && featuredAdIsSeenMoreThanADay()) {
      showSplash(featuredAd)
      localStorage.setItem('lastSeenFeaturedAd', new Date().toString())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuredAd]);

  const showSplash = (adData: AdvertisementResponse) => {
    setNextSplashCounter(0);
    presentDialog({
      headerText: '',
      content: <SplashAd adData={adData} onClose={dismissDialog} />,
      enableBackdropDismiss: false,
      hideHeader: true,
    });
  };

  useEffect(() => {
    if (authData) {
      removeTerms();
      removePrivacyPolicy();

      setIsHomeowner(authData.user.userRole === 'homeowner');

      if (authData.user && !authData.user.lastAcceptedTerms) {
        setGetTerms(true);
        refetchTerms();
      } else {
        setGetTerms(false);
        if (!authData.user.lastAcceptedPrivacyPolicy) {
          setGetDataPrivacy(true);
          refetchPrivacyPolicy();
        } else {
          setGetDataPrivacy(false);
        }
      }
    }
  }, [authData]);

  useEffect(() => {
    if (nextSplashCounter === adsFrequency) {
      refetchGetSplashAd();
    }
  }, [nextSplashCounter]);

  const clearToHide = () => {
    if (toHide) {
      excludeFromMatches(toHide.userId);
      setToHide(undefined);
    }
  };

  const onClickNextHandler = () => {
    if (hasNext) {
      clearToHide();
    }

    if (isLast) {
      showEndOfResultDialog();
    }

    nextMatch();
    setNextSplashCounter(nextSplashCounter + 1);
  };

  const onClickPreviousHandler = () => {
    if (hasPrev) {
      clearToHide();
    }

    previousMatch();
    setNextSplashCounter(nextSplashCounter + 1);
  };

  const onHideUserClickedHandler = async () => {
    if (shownData) {
      try {
        setShownDataIsHidden(true);
        setToHide(shownData);
        removeFromFavorites();
        presentToast({
          message: 'Kazam successfully hidden.',
          variant: 'success',
          position: 'bottom',
        });
        await postHide.mutateAsync();
      } catch (e: any) {
        console.log(e);
        presentToast({
          message: 'Failed to hide Kazam.',
          variant: 'error',
          position: 'bottom',
        });
      }
    }
  };

  const deleteHide = useDeleteHide(shownData?.userId || '');

  const onUnhideUserClickedHandler = async () => {
    if (shownData) {
      try {
        setShownDataIsHidden(false);
        setToHide(undefined);
        presentToast({
          message: 'Kazam successfully unhidden.',
          variant: 'success',
          position: 'bottom',
        });
        await deleteHide.mutateAsync();
      } catch (e: any) {
        console.log(e);
      }
    }
  };

  const removeFromHides = () => {
    if (shownData) {
      setShownDataIsHidden(false);
      setToHide(undefined);

      var newHides = hides.filter(item => item.tagUserId !== shownData.userId);
      setHides(newHides);
    }
  };

  const removeFromFavorites = () => {
    if (shownData) {
      setShownDataIsFavorite(false);
    }
  };

  const onMessageUserClickedHandler = async () => {
    if (shownData) {
      if (!jobHistoryDetails?.availableForChat) {
        showBlockedChatDialog()
        return
      }

      if (paywall && paywall.enabled) {
        if (!existingConversation
          && ((!userTrialSubscription && authData?.user.isEligibleForTrial)
            || (!!userTrialSubscription && userTrialSubscription.remainingFreeChats > 0))) {
            showTrialSubscriptionDialog()
            return
        }

        if (!userSubscription
          && ((!userTrialSubscription && !authData?.user.isEligibleForTrial)
            || (!!userTrialSubscription 
              && userTrialSubscription.remainingFreeChats === 0
              && !existingConversation))) {
            showPaywallDialog()
            return
        }
      }

      if (existingConversation) {
        // Has existing conversation
        if (shownDataIsHidden) {
          await deleteHide.mutateAsync();
          removeFromHides();
        }

        history.push(
          RoutePath.CHAT_ROOM.replace(
            ':id',
            existingConversation.conversationChannelId,
          ),
        );
      } else {
        await createConversation()
      }
    }
  };

  const postConversation = usePostConversation();
  const createConversation = async () => {
    try {
      var conversation = (await postConversation.mutateAsync({
        conversationChannelName: `Conversation with ${shownData?.userId}`, // Use user's name as conversation name
        memberIds: [shownData?.userId || '', authData?.user?.userId || '']
      })) as ChatResponse;
      
      if (conversation) {
        if (shownDataIsHidden) {
          removeFromHides();
        }
        history.push(
          RoutePath.CHAT_ROOM.replace(
            ':id',
            conversation.conversationChannelId,
          ),
        );
      }
    } catch (e: any) {
      console.log(e);
      const exception = e.data as KazamException;
      showError(exception ? exception.error : "Failed to create.")
    }
  }

  const showTrialSubscriptionDialog = () => {
    presentDialog({
      headerText: '',
      enableBackdropDismiss: true,
      content: (
        <TrialSubscriptionDialogContentView
          userId={shownData?.userId} 
          onSuccess={(id) => {
            dismissDialog();
            history.push(
              RoutePath.CHAT_ROOM.replace(':id', id)
            );
          }}
          onError={(v) => {
            showError(v);
          }}       />
      ),
    });
  }

  const showError = (message: string) => {
    presentToast({
      message: message,
      variant: 'error',
      position: 'bottom',
    })
  }

  const onOpenConversationClickedHandler = () => {
    history.push(RoutePath.CHAT);
  };

  const gotoNotifications = () => {
    history.push(RoutePath.NOTIFICATIONS);
  };

  const favoriteClickHandler = async () => {
    if (shownData) {
      try {
        removeFromHides();
        setShownDataIsFavorite(true);
        presentToast({
          message: 'Successfully added to favorites.',
          variant: 'success',
          position: 'bottom',
        });
        await postFavorite.mutateAsync();
        systemLogEvent('add_to_favorites_click');
      } catch (e: any) {
        console.log('error', e);
        presentToast({
          message: 'Failed to add to favorites.',
          variant: 'error',
          position: 'bottom',
        });
      }
    }
  };

  const deleteFavorite = useDeleteFavorite(shownData?.userId || '');

  const removeFavoriteClickHandler = async () => {
    if (shownData) {
      try {
        setShownDataIsFavorite(false);
        presentToast({
          message: 'Successfully removed from favorites.',
          variant: 'success',
          position: 'bottom',
        });
        await deleteFavorite.mutateAsync();

        systemLogEvent('remove_from_favorites_click');
      } catch (e: any) {
        console.log(e);
      }
    }
  };

  const waveClickHandler = async () => {
    systemLogEvent('unique_wave');

    if (!shownDataIsWaved) {
      // Should wave once only
      if (shownData) {
        try {
          setShownDataIsWaved(true);
          presentKawayToast(shownData);
          await postKaway.mutateAsync();
        } catch (e: any) {
          console.log('error', e);
          presentKawayToast(shownData);
        }
      }
    }
  };

  const presentKawayToast = (user: UserResponse) => {
    presentToast({
      message: `You have sent a kaway to ${user.firstName}.`,
      variant: 'success',
      position: 'bottom',
    });
  };

  const showRefreshDialog = () => {
    presentDialog({
      headerText: '',
      content: (
        <GenericConfirmationDialog
          headerText={localization.dlg_hdr_refresh_result}
          desc={localization.dlg_desc_refresh_result}
          rightButtonText={localization.btn_refresh}
          leftButtonText={localization.btn_cancel}
          onLeftButtonAction={dismissDialog}
          onRightButtonAction={() => {
            requeryMatch();
            dismissDialog();
          }}
        />
      ),
      enableBackdropDismiss: true,
    });
  };

  const showFetchingMatchDialog = () => {
    presentDialog({
      headerText: '',
      content: (
        <FetchingProfilesView />
      ),
      hideClose: true,
      enableBackdropDismiss: false,
    });
  };

  let profilesComponent: React.ReactNode | undefined = undefined;
  if (authData?.user?.userRole === 'kazam') {
    profilesComponent = (
      <div className="profile-container">
        <HomeownerProfileView profile={shownData} />
      </div>
    );
  } else {
    profilesComponent = (
      <div className="profile-container">
        <KazamProfileView profile={shownData} jobHistoryDetails={jobHistoryDetails}/>
      </div>
    );
  }

  const showPaywallDialog = () => {
    presentDialog({
      headerText: '',
      enableBackdropDismiss: true,
      content: (
        <SubscriptionsDialogContentView
          userId={shownData?.userId}
          onSuccess={() => dismissDialog()}
          onError={(v) => showError(v)} />
      )
    });
  };

  const showBlockedChatDialog = () => {
    presentDialog({
      headerText: '',
      content: (
        <GenericConfirmationDialog
          headerText="HIRED TAGGED"
          desc={`${shownData?.firstName} may be unavailable for 
            chat after ${format(jobHistoryDetails?.lockedProfileEndDate ?? new Date(), 'PP')}`}
        />
      ),
      enableBackdropDismiss: true,
    });
  };

  const getChatClass = (): string => {
    let className = "pointer-events-auto opacity-100"
    if (authData?.user?.userStatus === 'suspended') 
      className = "pointer-events-none opacity-40"

    if (!jobHistoryDetails?.availableForChat)
      className = "opacity-40"

    return className
  }

  return (
    <Page
      showRefresh
      showMenu
      showLoading={isFetching || fetchingSubscription}
      showRightComponent
      rightComponent={
        <div className="flex space-x-4">
          <MessageBadge onClick={onOpenConversationClickedHandler} />
          <NotificationBadge onClick={gotoNotifications} />
        </div>
      }
      unPadded
      showFilterSettings={true}
      onRefreshClick={showRefreshDialog}>
      <StyledMatchingLandingPage>
        {isHomeowner ? <FilterSettingsView /> : <KazamProfileStickyView />}

        <div
          className="content-container"
          style={{
            margin: isMobile ? '60px 0' : '0',
          }}>
          {shownData && !isFetching ? (
            profilesComponent
          ) : (
            <NoResultView
              image={noMatchIllustration}
              label={
                isHomeowner
                  ? localization.desc_no_matches
                  : localization.desc_no_matches_kazam
              }
              desc={
                isHomeowner
                  ? undefined
                  : localization.desc_no_matches_kazam_message
              }></NoResultView>
          )}
        </div>

        {showGetAppBanner && 
          <div>
            <GetAppCard
              fixed
              appUrl={appUrl}
              onClickNotNow={() => setShowGetAppBanner(false)}/>
          </div>}
        
        {shownData && (
          <div className="bottom-nav">
            <div className="flex-1 flex flex-row justify-start">
              {hasPrev && (
                <Icon
                  name="chevronLeft"
                  type="button"
                  color={colorTheme.dark}
                  onClick={onClickPreviousHandler}
                  size={36}
                />
              )}
            </div>

            <div className="bottom-nav-actions space-x-6">
              {isHomeowner && (
                <Icon
                  name={
                    shownDataIsHidden
                      ? 'xCircleFilledGradient'
                      : 'xCircleGradient'
                  }
                  type="button"
                  size={36}
                  onClick={
                    shownDataIsHidden
                      ? onUnhideUserClickedHandler
                      : onHideUserClickedHandler
                  }
                />
              )}
              <Icon
                name={
                  isHomeowner
                    ? shownDataIsFavorite // Homeowner's perspective
                      ? 'heartFilledGradient'
                      : 'heartGradient'
                    : shownDataIsWaved // Kazam/JobSeeker's perspective
                    ? 'waveGradientFilled' // TODO: Replace with filled wave icon
                    : 'waveGradient'
                }
                type={shownDataIsWaved ? 'default' : 'button'}
                onClick={
                  isHomeowner
                    ? shownDataIsFavorite
                      ? removeFavoriteClickHandler
                      : favoriteClickHandler // Homeowner's perspective
                    : waveClickHandler // Kazam/JobSeeker's perspective
                }
                size={isHomeowner ? 48 : 40}
              />

              {isHomeowner && (
                <Icon
                  name="messageCircleGradient"
                  type="button"
                  className={getChatClass()}
                  disabled={authData?.user?.userStatus === 'suspended'}
                  size={36}
                  onClick={onMessageUserClickedHandler}
                />
              )}
            </div>

            <div className="flex-1 flex flex-row justify-end">
              {hasNext && (
                <Icon
                  name="chevronRight"
                  type="button"
                  color={colorTheme.dark}
                  onClick={onClickNextHandler}
                  size={36}
                  className={`${isFetchingNext
                    ? 'opacity-40 pointer-events-none' 
                    : 'opacity-100 pointer-events-auto'
                  }`}
                />
              )}
            </div>
          </div>
        )}
      </StyledMatchingLandingPage>
    </Page>
  );
};

export default MatchingLandingPage;
