import {useEffect, useState} from 'react';
import Page from '../../../../core/components/page/Page';
import {
  Button,
  Checkbox,
  ImageUpload,
  Input,
  Select,
  SeparatedDatePickerComponent,
  Typehead,
  Typography,
} from '../../../../core/components';
import {StyledInputLabel} from './styled';
import {colorTheme} from '../../../../core/configs';
import {Controller, useForm} from 'react-hook-form';
import {
  PutKazamProfileBody,
  UploadPhotoReqBody,
  LocationResponse,
  Role,
  KazamRole,
  genderList,
  Gender,
  PatchUserProfileStatusBody,
  KazamException,
} from '../../../server/types';
import {yupResolver} from '@hookform/resolvers/yup';
import {editKazamProfileSchema} from '../../../schema/profile.schema';
import {
  getLocations,
  useGetMyProfile,
  usePatchUserProfileStatus,
  usePutKazamProfile,
  useUploadProfilePhoto,
} from '../../../server/react-query';
import {useHistory} from 'react-router-dom';
import {useQuery} from 'react-query';
import {format} from 'date-fns';
import {getGenderString} from '../../../utils/random.util';
import {useAuth} from '../../../hooks/useAuth';
import {isMobile} from 'react-device-detect';
import {LivingArrangement} from '../../../server/types/filter-settings.types';
import {Nullable} from '../../../types/types';
import EmploymentComponent from '../../finder-settings/EmploymentComponent';
import {localization} from '../../../localization/Localization';
import GenericConfirmationDialog from '../../../views/dialog-content/generic-dialog/GenericConfirmationDialog';
import {useDialog} from '../../../../core/components/dialog';
import {RoutePath} from '../../../navigation/config/RouteConfig';
import EditProfileStickyView from '../../../views/sticky-cards/edit-profile/EditProfileStickyView';
import { useToast } from '../../../../core/components/toast';
import RoleComponent from '../../finder-settings/RoleComponent';
import LockedFieldsView from '../locked-fields/LockedFieldsView';

interface RoleState {
  roleId: string;
  text: string;
  value: number;
  state: boolean;
}

const EditKazamUserProfilePage = () => {
  const history = useHistory();
  const {presentDialog, dismissDialog} = useDialog();
  const {updateAuthData, authData} = useAuth();
  const {presentToast} = useToast();

  const [kazamRoles, setKazamRoles] = useState<KazamRole[]>([]);
  const [roles, setRoles] = useState<RoleState[]>([]);
  const [locations, setLocations] = useState<LocationResponse[]>([]);
  const [selectedLocation, setselectedLocation] = useState<LocationResponse>();
  const [photo, setPhoto] = useState<UploadPhotoReqBody>({
    image: null,
  });
  const [fetchedPhoto, setFetchedPhoto] = useState('');
  const [livingArrangement, setLivingArrangement] =
    useState<Nullable<LivingArrangement>>(null);

  const [fetchLocations, setFetchLocations] = useState(false);
  const [userDisabled, setUserDisabled] = useState(false);
  const [selectedRoleIds, setSelectedRoleIds] = useState<string[]>([]);
  const [isVerified, setIsVerified] = useState<boolean>(false);

  const { data: profileInfo, isFetching: isFetchingProfile } = useGetMyProfile();
  const {data: locationsData, isFetching: isLocationFetching} = useQuery(
    ['get-locations'],
    getLocations,
    {
      enabled: fetchLocations,
    },
  );

  useEffect(() => {
    var locations = localStorage.getItem('locations');
    if (locations) {
      var locationsData = JSON.parse(locations) as LocationResponse[];
      setLocations(locationsData);
    } else {
      setFetchLocations(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileInfo]);

  useEffect(() => {
    if (locationsData && locationsData.length > 0) {
      setLocations(locationsData);
      localStorage.setItem('locations', JSON.stringify(locationsData));
      setFetchLocations(false)
    }
  }, [locationsData]);

  const setData = () => {
    if (profileInfo) {
      const birthDate = format(
        new Date(profileInfo.birthDate.toString()),
        'M/dd/yyyy',
      );
      const gender = getGenderString(profileInfo.gender as Gender);
      const locationId = profileInfo.filterSetting.location
        ? profileInfo.filterSetting.location.locationId
        : '';
      const minSalary = profileInfo.filterSetting.salary;
      const roles = profileInfo.filterSetting.kazamRoles?.map(
        kazamRole => kazamRole.role.roleId,
      );
      setSelectedRoleIds(roles)

      reset({
        ...profileInfo,
        gender,
        birthDate,
        locationId,
        minSalary,
        roles
      });
      setFetchedPhoto(profileInfo.photo);
      setKazamRoles(profileInfo.filterSetting.kazamRoles);
      setselectedLocation(profileInfo.filterSetting?.location);
      setLivingArrangement(profileInfo.filterSetting?.livingArrangement);
      setUserDisabled(profileInfo.userStatus === 'disabled');
      setIsVerified(profileInfo.verifiedStatus === 'verified')
    }
  }

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: {errors},
    setError,
  } = useForm<PutKazamProfileBody>({
    resolver: yupResolver(editKazamProfileSchema),
    defaultValues: {
      mobileNumber: '',
      firstName: '',
      lastName: '',
      birthDate: '',
      gender: '',
      email: '',
      minSalary: 1,
      locationId: '',
      livingArrangement: undefined,
      roles: []
    },
  });

  const putKazamProfile = usePutKazamProfile(getValues());
  const uploadMutation = useUploadProfilePhoto(photo);

  const submitForm = async () => {
    handleRoleError() && handleSubmit(handleSave)();
  }

  const handleRoleError = () => {
    const roles = getValues().roles || [];
    return roles.length > 0 && roles.length <= 3;
  };

  const handleSave = async () => {
    try {
      if (photo.image) {
        await uploadMutation.mutateAsync();
      }

      var user = await putKazamProfile.mutateAsync();
      let data = authData;
      if (data) {
        data.user = user;
        updateAuthData(data);
      }

      // clear stored match
      localStorage.removeItem('currentSessionTimeStamp');
      localStorage.removeItem('currentMatchesData');
      localStorage.removeItem('currentProfile');

      history.go(-1);
      // history.replace(RoutePath.MY_PROFILE);
    } catch (e: any) {
      console.log('error', e);
      const exception = e.data as KazamException;

      if (exception) {
        if (e.status
          && e.status === 403
          && exception.errorCode === 'SalaryLimitError') {
            setError("minSalary", {message: exception.error});
        }
        presentToast({
          message: exception.error,
          variant: 'error',
          position: 'bottom',
        });
      } else {
        presentToast({
          message: "Failed to save.",
          variant: 'error',
          position: 'bottom',
        });
      }
    }
  };

  const onSelectPhoto = (file: any | null) => {
    setPhoto({
      image: file,
    });
  };

  const selectedEmploymentHandler = (value: LivingArrangement | null) => {
    setValue('livingArrangement', value);
  };

  const disableAccount = () => {
    presentDialog({
      headerText: '',
      content: (
        <GenericConfirmationDialog
          headerText={localization.btn_disable_acc}
          desc={localization.dlg_desc_disable_acc}
          rightButtonText={localization.btn_disable}
          leftButtonText={localization.btn_cancel}
          onLeftButtonAction={dismissDialog}
          onRightButtonAction={() => {
            dismissDialog();
            history.push(RoutePath.DISABLE_ACCOUNT);
          }}
        />
      ),
      enableBackdropDismiss: true,
    });
  };

  const enableAccount = () => {
    presentDialog({
      headerText: '',
      content: (
        <GenericConfirmationDialog
          headerText={localization.btn_enable_acc}
          desc={localization.dlg_desc_enable_acc}
          rightButtonText={localization.btn_enable}
          leftButtonText={localization.btn_cancel}
          onLeftButtonAction={dismissDialog}
          onRightButtonAction={onProceedEnable}
        />
      ),
      enableBackdropDismiss: true,
    });
  };

  const patchUserProfileStatus = usePatchUserProfileStatus();
  const onProceedEnable = async () => {
    try {
      var body: PatchUserProfileStatusBody = {
        status: 'active',
        reason: '',
      };
      var user = await patchUserProfileStatus.mutateAsync(body);

      let data = authData;
      if (data) {
        data.user = user;
        updateAuthData(data);
      }
      dismissDialog();
      history.go(-1);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAccount = () => {
    presentDialog({
      headerText: '',
      content: (
        <GenericConfirmationDialog
          headerText={localization.btn_delete_acc}
          desc={localization.dlg_desc_del_acc}
          rightButtonText={localization.btn_delete}
          leftButtonText={localization.btn_cancel}
          onLeftButtonAction={dismissDialog}
          onRightButtonAction={() => {
            dismissDialog();
            history.push(RoutePath.DELETE_ACCOUNT);
          }}
        />
      ),
      enableBackdropDismiss: true,
    });
  };

  const onSalaryChange = (value: any) => {
    var data = value.currentTarget.value.replace(/,/g, '');
    setValue('minSalary', parseFloat(data));
  };

  const onChangePassword = () => {
    history.push(RoutePath.CHANGE_ACCOUNT_PASSWORD);
  };

  const selectedRoleHandler = (value: string[]) => {
    setValue('roles', value);
  };

  const goToCurrentLocation = () => {
    history.push(RoutePath.SAVE_CURRENT_LOCATION);
  }

  return (
    <Page
      showBackButton
      showDesktopBackButton
      showRightText={isMobile}
      showDesktopSaveButton={!isMobile}
      onRightActionClick={submitForm}
      rightActionIsLoading={
        putKazamProfile.isLoading || uploadMutation.isLoading
      }
      showLoading={isLocationFetching}>
      <EditProfileStickyView />
      <div
        className="space-y-4"
        style={isMobile ? {paddingTop: '4rem'} : undefined}>
        <Typography
          label={localization.hdr_edit_profile}
          variant="f3"
          weight="semibold"
          align="center"
          color={colorTheme.dark}
        />
        <div className="space-y-4">
          <div className="flex justify-center">
            <ImageUpload
              onSelectPhoto={onSelectPhoto}
              providedPhoto={fetchedPhoto}
              readOnly={isVerified}
            />
          </div>

          {isVerified && 
            <LockedFieldsView onContinue={() => setIsVerified(false)}/>
          }
          
          <div>
            <Controller
              control={control}
              name="firstName"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  label={localization.lbl_first_name}
                  inputSize="large"
                  placeholder={localization.ph_enter_first_name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={errors.firstName?.message}
                  maxLength={20}
                  disabled={isVerified}
                  verified={isVerified}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="lastName"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  label={localization.lbl_last_name}
                  inputSize="large"
                  placeholder={localization.ph_enter_last_name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={errors.lastName?.message}
                  maxLength={20}
                  disabled={isVerified}
                  verified={isVerified}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="birthDate"
              render={({field: {value, onBlur, onChange}}) => (
                // !isMobile ? (
                //   <DatePicker
                //     value={new Date(value)}
                //     onChange={onChange}
                //     placeholder={localization.ph_enter_birthdate}
                //     error={errors.birthDate?.message}
                //   />
                // ) : (
                //   <Input
                //     className={value ? 'has-value' : 'empty date-class'}
                //     label={localization.lbl_birthdate}
                //     inputSize="large"
                //     type={!isMobile ? 'text' : 'date'}
                //     placeholder={localization.ph_enter_birthdate}
                //     value={value ? value.toString() : ''}
                //     onFocus={e => {
                //       e.currentTarget.type = 'date';
                //       e.currentTarget.click();
                //     }}
                //     onBlur={e =>
                //       value && !isMobile
                //         ? (e.currentTarget.type = 'date')
                //         : e.currentTarget.blur()
                //     }
                //     onChange={onChange}
                //     error={errors.birthDate?.message}
                //   />
                // )
                <SeparatedDatePickerComponent
                  label={localization.lbl_birthdate}
                  value={new Date(value)}
                  onChange={onChange}
                  placeholder={localization.ph_enter_birthdate}
                  error={errors.birthDate?.message}
                  disabled={isVerified}
                  verified={isVerified}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="gender"
              render={({field: {value, onChange}}) => (
                <Select
                  label={localization.lbl_gender}
                  placeholder={localization.ph_select_gender}
                  items={genderList}
                  itemRenderer={(item, selected) => (
                    <Typography
                      label={item}
                      variant="f1"
                      color={colorTheme.dark}
                      weight={selected ? 'semibold' : 'normal'}
                      className="flex-1"
                    />
                  )}
                  onChange={onChange}
                  value={value}
                  valueRenderer={value => value}
                  error={errors.gender?.message}
                  disabled={isVerified}
                  verified={isVerified}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="mobileNumber"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  disabled
                  label={localization.lbl_mobile_no}
                  type="tel"
                  inputSize="large"
                  placeholder={localization.ph_enter_mobile}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={errors.mobileNumber?.message}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="email"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  label={localization.lbl_email}
                  type="email"
                  inputSize="large"
                  placeholder={localization.ph_enter_email}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={errors.email?.message}
                />
              )}
            />
          </div>
          <div>
            <div className="space-y-1 pb-2">
              <StyledInputLabel data-testid="label-element">
                {localization.lbl_min_salary}
              </StyledInputLabel>
              {/* <Typography
                label={localization.desc_salary}
                variant="f1"
                align="left"
                color={colorTheme.danger}
              /> */}
            </div>
            <Controller
              control={control}
              name="minSalary"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  type="number"
                  inputSize="large"
                  placeholder={localization.ph_enter_salary}
                  value={value}
                  onBlur={onBlur}
                  onChange={onSalaryChange}
                  error={errors.minSalary?.message}
                  showThousandSeparator
                />
              )}
            />
          </div>
          <div>
            {locations && locations.length > 0 && (
              <>
                <Controller
                  control={control}
                  name="locationId"
                  render={({field: {value, onChange}}) => (
                    <Typehead
                      label={localization.lbl_your_loc}
                      items={locations}
                      placeholder={localization.ph_search_loc}
                      onChange={sel => {
                        var item = sel && sel.length > 0 ? sel[0].locationId : '';
                        onChange(item);
                      }}
                      selected={selectedLocation}
                      error={errors.locationId?.message}></Typehead>
                  )}
                />
              
                <div onClick={goToCurrentLocation}>
                  <Typography
                    className="cursor-pointer"
                    label="Use current location"
                    variant="f1"
                    weight="normal"
                    color={colorTheme.primary}
                  />
                </div>
              </>
            )}
          </div>
          <div>
            <EmploymentComponent
              value={livingArrangement}
              onChange={selectedEmploymentHandler}
            />
          </div>
          <div className="space-y-4 pb-6">
            <RoleComponent
              value={selectedRoleIds}
              onChange={selectedRoleHandler}
            />
          </div>

          <Button
            label={localization.btn_change_password}
            color="primary"
            variant="contained"
            onClick={onChangePassword}
          />

          <div className="space-y-4">
            {userDisabled && (
              <Button
                label={localization.btn_enable_acc}
                color="primary"
                onClick={enableAccount}
              />
            )}

            {!userDisabled && (
              <Button
                label={localization.btn_disable_acc}
                color="darkFaded"
                variant="outlined"
                onClick={disableAccount}
              />
            )}

            <Button
              label={localization.btn_delete_acc}
              color="darkFaded"
              onClick={deleteAccount}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default EditKazamUserProfilePage;
