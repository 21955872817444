import {Icon, PersonalityMatchTag, ProfileStatus, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {Gender, KazamRole, UserResponse} from '../../../server/types';
import {
  StyledDefaultProfileView,
  StyledImage,
  StyledProfileOverlay,
} from './styles';
import {useEffect, useState} from 'react';
import WrappedProfileStatus from '../../../components/wrapped-profile-status/WrappedProfileStatus';
import {calculateAge} from '../../../utils/date.util';
import {toNumberWithCommas} from '../../../utils/decimal.util';
import {getGenderString} from '../../../utils/random.util';
import {LivingArrangement} from '../../../server/types/filter-settings.types';
import _ from 'lodash';
import {localization} from '../../../localization/Localization';
import { ProgressiveImage } from '../../../../core/components/progressive-image/ProgressiveImage';
import VerifiedTag from '../../../../core/components/verified-tag';
import HiredTag from '../../../../core/components/hired-tag';
import NoShowTag from '../../../../core/components/no-show-tag';
import KazamExperience from '../../../components/kazam-experience/KazamExperience';
import { useHistory } from 'react-router-dom';
import { RoutePath } from '../../../navigation/config/RouteConfig';
import { JobHistoryDetails, JobHistoryResponse } from '../../../server/types/job-offer.types';
import { getJobHistoryDetails } from '../../../utils/job-offer.util';
import { useDialog } from '../../../../core/components/dialog';
import GenericConfirmationDialog from '../../dialog-content/generic-dialog/GenericConfirmationDialog';

interface KazamProfileViewProps {
  profile?: UserResponse;
  className?: string;
  adminView?: boolean
  jobHistoryDetails?: JobHistoryDetails
  myProfile?: boolean
}

const KazamProfileView = (props: KazamProfileViewProps) => {
  const {profile, jobHistoryDetails} = props;  
  const history = useHistory()
  const {presentDialog, dismissDialog} = useDialog();
  
  const isVerified = profile?.verifiedStatus === 'verified'
  const showPersonalityMatch = !!profile?.personalityMatch && !props?.myProfile
  const personalityMatchScore = profile?.personalityMatchScore
    ? `${Math.round(profile?.personalityMatchScore * 100)}`
    : '**'
  
  const [label, setLabel] = useState<string>('');
  const [kazamAgeLabel, setKazamAgeLabel] = useState<string>('');
  const [livingArrangementLabel, setLivingArrangementLabel] = useState<string>(
    '',
  );
  const [concatenatedRoles, setConcatenatedRoles] = useState<string>('');
  const [jobHistory, setJobHistory] = useState<JobHistoryResponse[]>([]);
  const [isHired, setIsHired] = useState<boolean>(false);
  const [isNoShow, setIsNoShow] = useState<boolean>(false);

  useEffect(() => {
    if (jobHistoryDetails) {
      setIsHired(jobHistoryDetails.isHired)
      setIsNoShow(jobHistoryDetails.isNoShow)
      setJobHistory(jobHistoryDetails.sortedJH)
    } else {
      setIsHired(false)
      setIsNoShow(false)
      setJobHistory([])
    }
  }, [jobHistoryDetails]);

  useEffect(() => {
    if (profile) {
      getProfileDescription(profile);
    }
  }, [profile]);

  const getProfileDescription = (profile: UserResponse) => {
    const age = calculateAge(new Date(profile.birthDate));

    let newLocationLabel = profile.filterSetting.location?.text || '';
    if (profile.filterSetting.location
      && profile.filterSetting.location.text !== profile.filterSetting.location.adminName) {
        newLocationLabel = `${newLocationLabel}, ${profile.filterSetting.location.adminName}`
      }

    let newKazamAgeLabel = `${age} ${getGenderString(
      profile.gender as Gender,
    )}, ${newLocationLabel}`;
    
    setKazamAgeLabel(newKazamAgeLabel);

    const newLivingArrangementLabel = getLivingArrangementLabel(
      profile.filterSetting.livingArrangement,
    );
    setLivingArrangementLabel(newLivingArrangementLabel);

    const concatenatedRoles = getConcatenatedRoles(
      profile.filterSetting.kazamRoles,
    );
    setConcatenatedRoles(concatenatedRoles);

    const newLabel = `${localization.ho_pr_i_am} ${profile.firstName},
      ${age} ${localization.kzm_pr_age} ${newLocationLabel},
      ${localization.kzm_pr_apply} ${getRoleDescription(
      profile.filterSetting.kazamRoles,
      concatenatedRoles,
    )}.
      ${localization.kzm_pr_min_salary} ₱ ${toNumberWithCommas(
      profile.filterSetting.salary || 0,
    )} ${localization.kzm_pr_per_mo}
      ${newLivingArrangementLabel.toLocaleLowerCase()} ${
      localization.ho_pr_setup
    }.`;
    setLabel(newLabel);
  };

  const getRoleDescription = (
    roles: KazamRole[],
    concatenatedRoles: string,
  ) => {
    if (roles.length > 1) {
      return `${localization.kzm_pr_roles_of} ${concatenatedRoles}`;
    } else {
      return `${localization.kzm_pr_role_of} ${concatenatedRoles}`;
    }
  };

  const getConcatenatedRoles = (roles: KazamRole[]) => {
    var roleCopy = _.cloneDeep(roles);
    if (roleCopy.length === 2) {
      return `${roleCopy
        .map(kr => kr.role.text)
        .join(` ${localization.ho_pr_and} `)}`;
    } else if (roleCopy.length > 2) {
      const lastRole = roleCopy.pop();
      const commaRoles = roleCopy.map(kr => kr.role.text).join(', ');
      return `${commaRoles} ${localization.ho_pr_and} ${lastRole?.role.text}`;
    } else {
      return `${roleCopy[0].role.text}`;
    }
  };

  const getLivingArrangementLabel = (la: LivingArrangement) => {
    switch (la) {
      case 'stayIn':
        return 'Stay-In';
      case 'stayOut':
        return 'Stay-Out';
      default:
        return `Stay-In ${localization.ho_pr_or} Stay-Out`;
    }
  };

  const handlePersonalityMatchTagClick = () => {
    if (personalityMatchScore === '**') {
      presentDialog({
        headerText: '',
        content: (
          <GenericConfirmationDialog
            headerText="Do your personalities match?"
            alignHeader='center'
            desc="Ang user na ito ay kumuha ng personality matching test. 
              Para malaman kung gaano katugma ang inyong personality, 
              maaari ring sagutin ang Personality test."
            rightButtonText="Take Personality Test"
            rightButtonColor='danger'
            onRightButtonAction={() => {
              dismissDialog()
              history.push(RoutePath.TAKE_PERSONALITY_TEST)
            }}
          />
        ),
        enableBackdropDismiss: false,
        hideClose: false
      });
    } else {
      history.push(RoutePath.TAKE_PERSONALITY_TEST)
    }
  }

  return (
    <div className={['space-y-4', props.className].join(' ')}>
      <StyledDefaultProfileView className="flex items-center justify-center w-full">
        {profile && profile.photo ? (
          // <StyledImage key={profile.photo} src={profile.photo}></StyledImage>
          <ProgressiveImage key={profile.photo} imgSrc={profile.photo}/>
        ) : (
          <Icon name="user" size={215} color={colorTheme.lightGray}></Icon>
        )}

        <StyledProfileOverlay className="flex items-end">
          <div className="space-y-2 flex1">
            <div>
              <div className='flex items-center space-x-1'>
                <Typography
                  className="overflow"
                  label={
                    props.profile?.firstName
                      ? props.profile?.firstName
                      : 'First name'
                  }
                  variant="f3"
                  weight="semibold"
                  color="white"
                />

                {isVerified && <VerifiedTag />}
                {showPersonalityMatch && <PersonalityMatchTag />}
              </div>
              
              <Typography
                label={kazamAgeLabel}
                variant="f2"
                weight="normal"
                color="white"
              />
              {profile?.userId ? (
                <WrappedProfileStatus userId={profile.userId} />
              ) : (
                <ProfileStatus status="online" />
              )}
            </div>
            <div>
              <Typography
                label={concatenatedRoles}
                variant="f1"
                weight="normal"
                color={colorTheme.light}
              />
              <Typography
                label={`₱ ${toNumberWithCommas(
                  profile?.filterSetting.salary || 0,
                )} ${localization.ho_pr_per_mo} ● ${livingArrangementLabel}`}
                variant="f1"
                weight="semibold"
                color={colorTheme.secondary}
              />
            </div>
          </div>
        </StyledProfileOverlay>
      </StyledDefaultProfileView>

      <div className='flex gap-2 flex-wrap'>
        {isVerified && 
          <VerifiedTag
            type='verifiedText'
            onClickVerifiedText={() => !props?.adminView && history.push(RoutePath.GET_VERIFIED)}
          />
        }

        {showPersonalityMatch && 
          <PersonalityMatchTag
            type='full'
            score={personalityMatchScore}
            onClick={handlePersonalityMatchTagClick}
          />
        }

        {isHired &&
          <HiredTag
            type='HiredText'
            userName={profile?.firstName+' '+profile?.lastName}
          />  
        }

        {isNoShow && 
          <NoShowTag
            type='NoShowText'
          />
        }
      </div>
      
      <div>
        <Typography
          label={label}
          variant="f1"
          weight="semibold"
          color={colorTheme.darkFaded}
        />
      </div>

      <div className='mt-2'>
          <KazamExperience jobHistory={jobHistory}/>
      </div>
    </div>
  );
};

export default KazamProfileView;
