import { IVersionInfo } from './core/models/IVersionInfo';

export class KazamVersionInfo implements IVersionInfo {
  name = process.env.REACT_APP_NAME || "Kazam";
  version = process.env.REACT_APP_VERSION || "1.5.1";
  build = process.env.REACT_APP_BUILD || "1889";
  baseUrl = process.env.REACT_APP_BASE_URL || "@BASE_URL";
  target = process.env.REACT_APP_TARGET || "prod";
  hash = process.env.REACT_APP_HASH || "b53f1275cfed93706af488bd6fbfbc8264adb93c";
  branch = process.env.REACT_APP_BRANCH || "chore/update-gtag";

  public toString(): string {
    return `${this.name} ${this.version} b${this.build}`;
  }
}

export const VersionInfo = new KazamVersionInfo();
