import { useDialog } from "../../../../core/components/dialog";
import { useToast } from "../../../../core/components/toast";
import { useAuth } from "../../../hooks/useAuth";
import { usePatchUserVerifiedStatus } from "../../../server/react-query/useAdminUsers";
import GenericConfirmationDialog from "../../../views/dialog-content/generic-dialog/GenericConfirmationDialog";
import { StyledLabel, StyledLink } from "./styled";

interface LockedFieldsViewProps {
  onContinue?: () => void
}

const LockedFieldsView = (props: LockedFieldsViewProps) => {
  const {presentDialog, dismissDialog} = useDialog()
  const {presentToast} = useToast()
  const {authData} = useAuth()

  const showConfirmationDialog = () => {
    presentDialog({
      headerText: '',
      content: (
      <GenericConfirmationDialog
          headerText="Edit ID Verified profile"
          desc="Continuing to edit your profile will remove your ID Verified tag.  You will need to request verification again."
          rightButtonText="Continue"
          leftButtonText="Cancel"
          onLeftButtonAction={dismissDialog}
          onRightButtonAction={revokeVerifiedStatus}
      />
      ),
      enableBackdropDismiss: false
  }); 
  }

  const patchUserVerifiedStatus = usePatchUserVerifiedStatus()
  const revokeVerifiedStatus = async () => {
    try {
      if (authData?.user?.userId) {
        await patchUserVerifiedStatus.mutateAsync({
          userId: authData?.user?.userId,
          verifiedStatus: 'notVerified'
        });

        dismissDialog()
        props?.onContinue && props.onContinue()
  
        presentToast({
          message: 'Successfully removed ID Verified tag.',
          variant: 'success',
          position: 'bottom',
        });
      }
      
    } catch (e) {
      console.log(e);
      presentToast({
        message: `Unexpected error was occurred.`,
        variant: 'error',
        position: 'bottom',
      });
    }
  }
  
  return (
    <div className="flex flex-col justify-center items-center">
      <StyledLabel>
        Your profile is ID Verified! Editing profile details like your name, photo, and birthday will reset your ID Verified status so we discourage making edits.
      </StyledLabel>
      <StyledLink onClick={showConfirmationDialog}>
        Edit ID Verified profile
      </StyledLink>
    </div>
  );
};

export default LockedFieldsView;
