import {
  Button,
  Icon,
  Typography,
  WizardStep,
} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import {useHistory} from 'react-router-dom';
import Page from '../../../core/components/page/Page';
import logo from '../../../assets/KAZAM_logo_Transparent.svg';
import PersonalityTestOption from './PersonalityTestOption';
import { useEffect, useState } from 'react';
import { STORAGE } from '../../utils/storage.utils';
import { PersonalityTestData } from '../../server/types/personality-test.types';
import { useDialog } from '../../../core/components/dialog';
import GenericConfirmationDialog from '../../views/dialog-content/generic-dialog/GenericConfirmationDialog';
import { RoutePath } from '../../navigation/config/RouteConfig';
import _ from 'lodash';
import { usePutPersonalityMatch } from '../../server/react-query/usePersonalityMatch';
import { isMobile } from 'react-device-detect';
import { localization } from '../../localization/Localization';

const PersonalityTestQuestion = (props: any) => {
  const history = useHistory();
  const {presentDialog, dismissDialog} = useDialog();
  const isLastQuestion = props?.questionNumber === 10

  const [selectedOption, setSelectedOpton] = useState<number>()
  const [personalityTestData, setPersonalityTestData] = useState<PersonalityTestData[]>([])
  const [error, setError] = useState<string | undefined>()
  const [alreadyCompletedTest, setAlreadyCompletedTest] = useState<boolean>()
  
  const options = [
    'Not at all  | Hinding-hindi',
    'Rarely | Madalang lang',
    'Sometimes | Paminsan-minsan',
    'Often | Madalas',
    'Always | Parati/Lagi-lagi'
  ]

  useEffect(() => {
    var testData = STORAGE.get<PersonalityTestData[]>('personalityTest', true)

    if (testData){
      setPersonalityTestData(testData)
      setAlreadyCompletedTest(testData?.length === 10)
    }
    
    if (testData
      && props?.questionNumber
      && testData.length >= props.questionNumber
    ) {
      var data = testData[props.questionNumber-1] as PersonalityTestData
      setSelectedOpton(data.answer)
    } else {
      setSelectedOpton(undefined)
    }
  }, [props?.data])

  const handleNext = () => {
    if (!!!selectedOption){
      setError("Please select an answer.")
      return
    }
    savePersonalityTest()
    props.nextStep();
  };

  const handleBack = () => {
    if (props?.questionNumber === 1) {
      history.go(-1)
    } else {
      props.previousStep(); 
    }
  };

  const handleClose = () => {
    presentDialog({
      headerText: '',
      content: (
        <GenericConfirmationDialog
          headerText=""
          desc={alreadyCompletedTest 
            ? "Are you sure you want to close the Personality Test? Your updated answers will not be saved."
            : "Are you sure you want to close the Personality Test without completing it? Closing it will mean that you will not be able to see how well you match other users."
          }
          rightButtonText="Close"
          leftButtonText='Cancel'
          onLeftButtonAction={dismissDialog}
          onRightButtonAction={() => {
            dismissDialog()
            savePersonalityTest()
            history.replace(RoutePath.MATCHES_LANDING)
            var previousLanguage = localStorage.getItem('previousLanguange')
            localization.setLanguage(previousLanguage ?? "en")
            localStorage.setItem('language', previousLanguage ?? "en");
          }}
        />
      ),
      enableBackdropDismiss: false,
      hideClose: true
    });
  };


  const putPersonalityMatch = usePutPersonalityMatch()
  const handleSubmit = async () => {
    if (!!!selectedOption){
      setError("Please select an answer.")
      return
    }
    try {
      var answers = personalityTestData?.map(d => d.answer);
      if (answers.length === props?.questionNumber) {
        answers[props.questionNumber-1] = selectedOption
      } else {
        answers.push(selectedOption)
      }
      
      if (answers) {
        await putPersonalityMatch.mutateAsync({
          answers: answers.toString()
        })
      }
      
      savePersonalityTest()
      history.replace(RoutePath.PERSONALITY_TEST_SUCCESS)
    } catch (error) {
      
    }
  };

  const savePersonalityTest = (close: boolean = false) => {
    var personalityTest = _.cloneDeep(personalityTestData)
    
    if (personalityTest
      && props?.questionNumber
      && personalityTest.length >= props.questionNumber
    ) {
      personalityTest[props.questionNumber-1].answer = selectedOption
    } else {
      var questionData = props?.data as PersonalityTestData
      questionData.answer = selectedOption
      personalityTest.push(questionData)
    }
    STORAGE.save('personalityTest', personalityTest, true)
  }

  return (
    <Page hideNavbar>
      <div className="space-y-6">
        <div className='flex justify-end'>
          <Icon
            name='x'
            type='button'
            color={colorTheme.dark}
            onClick={handleClose}
          />
        </div>
        <div>
          <WizardStep
            steps={props.totalSteps}
            currentStep={props.currentStep}
          />
        </div>

        <div className="flex justify-center">
          <img style={{height: '29px'}} src={logo} alt="Logo" />
        </div>

        <div className='flex flex-col lg:justify-between'
          style={{
            minHeight: isMobile
              ? '100%'
              : '560px',
              paddingBottom: isMobile ? '114px' : '0px'
          }}>
          <div className="space-y-4">
            <div>
              <div>
                <Typography
                  label={`Question No. ${props?.questionNumber}`}
                  variant="f3"
                  align="center"
                  color={colorTheme.dark}
                />
              </div>
              <div className="pt-1">
                <Typography
                  label={localization.getString(props?.data?.question ?? "")}
                  variant="f2"
                  align="center"
                  color={colorTheme.dark}
                />
              </div>
            </div>

            {error &&
              <Typography
                label={error}
                variant="f1"
                align="center"
                color={colorTheme.danger}
              />
            }

            <div className='space-y-6'>
              {options.map((o, i) => {
                return (
                  <PersonalityTestOption
                    key={i}
                    selected={selectedOption === (i+1)}
                    value={i+1}
                    text={o}
                    onSelect={() => {
                      setSelectedOpton(i+1)
                      setError(undefined)
                    }}
                  />
                )
              })}
            </div>
          </div>

          <div className='fixed bottom-6 left-6 right-6 lg:relative lg:w-full lg:left-0 lg:bottom-0 bg-bgWhite'>
            <div>
              <div>
                <Button
                  label={isLastQuestion ? "Submit" : "Next"}
                  color="primary"
                  onClick={isLastQuestion ? handleSubmit : handleNext}
                  isLoading={isLastQuestion && putPersonalityMatch.isLoading}
                />
              </div>
              <div className="pt-4">
                <Button
                  label="Back"
                  variant="outlined"
                  onClick={handleBack}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
export default PersonalityTestQuestion;
