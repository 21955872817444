import React, {useEffect, useState} from "react";
import {Typography} from "../../../../core/components";
import {colorTheme} from "../../../../core/configs";
import {StyledAdminUsersTabs} from "./styled";
import { RoutePath } from "../../../navigation/config/RouteConfig";
import { useHistory } from "react-router-dom";

export type TabIndex = 0 | 1 | 2 | 3 | 4 | 5;

interface AdminUsersTabsProps {
  selected: TabIndex;
  className?: string;
}

const AdminUsersTabs = (props: AdminUsersTabsProps) => {
  const history = useHistory();
  
  const {selected, className} = props;
  const items = [
    'All Users',
    'Reported',
    'Banned',
    'Suspended',
    'For Verification',
    'Verified'
  ]

  const [activeTabIndex, setActiveTabIndex] = useState<TabIndex>(selected);

  useEffect(() => {
    setActiveTabIndex(selected);
  }, [selected]);

  const onTabClickedHandler = (index: number) => {
    if (index != activeTabIndex) {
      switch (index) {
        case 0:
          history.replace(RoutePath.ADMIN_USERS_ALL);
          break;
        case 1:
          history.replace(RoutePath.ADMIN_USERS_REPORTED);
          break;
        case 2:
          history.replace(RoutePath.ADMIN_USERS_BANNED);
          break;
        case 3:
          history.replace(RoutePath.ADMIN_USERS_SUSPENDED);
          break;
        case 4:
          history.replace(RoutePath.ADMIN_USERS_FOR_VERIFICATION);
          break;
        case 5:
          history.replace(RoutePath.ADMIN_USERS_VERIFIED);
          break;
        default:
          break;
      }
    }
  };

  return (
    <StyledAdminUsersTabs className={['tabs-control flex flex-row', className].join(' ')}>
      {items?.map((item, i) => {
        return (
          <div
            className={["tab-control cursor-pointer", activeTabIndex ===i ? 'active' : ''].join(' ')}
            onClick={() => onTabClickedHandler(i)}>
              <Typography
                label={item}
                variant="f2"
                weight="semibold"
                color={activeTabIndex === i ? colorTheme.white : colorTheme.dark}
                align="center" 
              />
          </div>
        )
      })}
    </StyledAdminUsersTabs>
  );
};

export default AdminUsersTabs;
