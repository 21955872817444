import {colorTheme} from '../../configs';
import Icon from '../icon';
import Typography from '../typography';
import { StyledDiv, StyledCircleDiv } from './styled';

export interface VerifiedTagProps {
  type?: 'iconOnly' | 'verifiedText'
  onClickVerifiedText?: () => void
}

const VerifiedTag = (props: VerifiedTagProps) => {
  const {
    type = 'iconOnly',
    onClickVerifiedText
  } = props;

  if (type === 'verifiedText') {
    return (
      <StyledDiv className='space-x-1 cursor-pointer'
        onClick={onClickVerifiedText}>
        <Icon
          name="checkCircle"
          size={16}
          color={colorTheme.white}
        />

        <Typography
          label="ID Verified"
          variant="f2"
          weight='semibold'
          color={colorTheme.white}
        />
      </StyledDiv>
    )
  }

  return (
    <StyledCircleDiv>
      <Icon
        name="checkCircle"
        size={12}
        color={colorTheme.white}
      />
    </StyledCircleDiv>
  );
};

export default VerifiedTag;
