import {
  AlertCircle,
  AlertTriangle,
  ArrowLeft,
  ArrowRight,
  Bell,
  BookOpen,
  Book,
  Brain,
  Camera,
  CheckCircle,
  CheckSquare,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  CreditCard,
  Code,
  Copy,
  DeclineVoice,
  Download,
  Edit,
  Email,
  Eye,
  EyeOff,
  EndVideo,
  EndVoice,
  Facebook,
  FileText,
  FilePlus,
  Filter,
  Flag,
  FlipCamera,
  HeartFilled,
  Heart,
  HeartGradient,
  HeartFilledGradient,
  History,
  Home,
  ImageAdd,
  Image,
  Instagram,
  LinkedIn,
  LogOut,
  Menu,
  MessageCircle,
  MessageCircleGradient,
  MessageMulti,
  MicDisabled,
  MicEnabled,
  MicEnabledWhite,
  MissedVideo,
  MissedVoice,
  MoreHorizontal,
  Paperclip,
  Plus,
  PlusBig,
  RadioButtonFilled,
  RadioButton,
  Repeat,
  RotateCw,
  Search,
  Send,
  Settings,
  Share,
  Shield,
  Sliders,
  Square,
  Twitter,
  User,
  Users,
  VideoCall,
  VoiceCall,
  Voucher,
  Wave,
  WaveGradient,
  WaveGradientFilled,
  XCircle,
  XCircleFilled,
  XCircleGradient,
  XCircleFilledGradient,
  X,
  BxUpArrow,
  BxDownArrow,
  Check,
  DocumentChecked,
  UserCross,
  BgHome,
  ThemeDocumentChecked,
  PlusDark,
  DocumentCheckedLight,
  LocationLight,
  CrossLight,
  Star,
  StarFilled
} from '../../../core/assets/icons/components';

import {IconName} from './icon.types';

export const icons: {[key in IconName]: typeof AlertCircle} = {
  alertCircle: AlertCircle,
  alertTriangle: AlertTriangle,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  bell: Bell,
  bookOpen: BookOpen,
  book: Book,
  brain: Brain,
  camera: Camera,
  check: Check,
  checkCircle: CheckCircle,
  checkSquare: CheckSquare,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronUp: ChevronUp,
  creditCard: CreditCard,
  code: Code,
  copy: Copy,
  declineVoice: DeclineVoice,
  download: Download,
  edit: Edit,
  email: Email,
  eye: Eye,
  eyeOff: EyeOff,
  endVideo: EndVideo,
  endVoice: EndVoice,
  facebook: Facebook,
  fileText: FileText,
  filePlus: FilePlus,
  filter: Filter,
  flag: Flag,
  flipCamera: FlipCamera,
  heartFilled: HeartFilled,
  heart: Heart,
  heartGradient: HeartGradient,
  heartFilledGradient: HeartFilledGradient,
  history: History,
  home: Home,
  imageAdd: ImageAdd,
  image: Image,
  instagram: Instagram,
  linkedIn: LinkedIn,
  logOut: LogOut,
  menu: Menu,
  messageCircle: MessageCircle,
  messageCircleGradient: MessageCircleGradient,
  messageMulti: MessageMulti,
  micDisabled: MicDisabled,
  micEnabled: MicEnabled,
  micEnabledWhite: MicEnabledWhite,
  moreHorizontal: MoreHorizontal,
  missedVideo: MissedVideo,
  missedVoice: MissedVoice,
  paperclip: Paperclip,
  plus: Plus,
  plusBig: PlusBig,
  radioButtonFilled: RadioButtonFilled,
  radioButton: RadioButton,
  repeat: Repeat,
  rotateCw: RotateCw,
  search: Search,
  send: Send,
  settings: Settings,
  share: Share,
  shield: Shield,
  sliders: Sliders,
  star: Star,
  starFilled: StarFilled,
  square: Square,
  twitter: Twitter,
  user: User,
  users: Users,
  videoCall: VideoCall,
  voiceCall: VoiceCall,
  voucher: Voucher,
  wave: Wave,
  waveGradient: WaveGradient,
  waveGradientFilled: WaveGradientFilled,
  xCircle: XCircle,
  xCircleFilled: XCircleFilled,
  xCircleGradient: XCircleGradient,
  xCircleFilledGradient: XCircleFilledGradient,
  x: X,
  bxUpArrow: BxUpArrow,
  bxDownArrow: BxDownArrow,
  documentChecked:DocumentChecked,
  userCross:UserCross,
  bgHome:BgHome,
  themeDocumentChecked:ThemeDocumentChecked,
  plusDark:PlusDark,
  documentCheckedLight:DocumentCheckedLight,
  locationLight: LocationLight,
  crossLight: CrossLight
};

export const getIconComponentByName = (name: IconName) => {
  return icons[name];
};
