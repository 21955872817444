import {
  Button,
  Typography,
} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import {useHistory} from 'react-router-dom';
import vector from '../../../assets/shake-hands.svg';
import Page from '../../../core/components/page/Page';
import logo from '../../../assets/KAZAM_logo_Transparent.svg';
import { Action } from 'history';
import { useAuth } from '../../hooks/useAuth';
import { isMobile } from 'react-device-detect';
import { localization } from '../../localization/Localization';

const PersonalityTestSuccess = () => {
  const history = useHistory();
  const {authData} = useAuth();
  const isHomeowner = authData?.user?.userRole === 'homeowner'

  const onClose = () => {
    var previousLanguage = localStorage.getItem('previousLanguange')
    localization.setLanguage(previousLanguage ?? "en")
    localStorage.setItem('language', previousLanguage ?? "en");

    if (history.action === Action.Push) {
      history.go(-1);
    } else {
      history.replace('/');
    }
  }

  return (
    <Page hideNavbar>
      <div className="space-y-14">
        <div className="flex justify-center">
          <img style={{height: '29px'}} src={logo} alt="Logo" />
        </div>

        <div className='flex flex-col lg:justify-between'
          style={{
            minHeight: isMobile
              ? '100%'
              : '500px'
          }}>
          <div>
            <div className="flex justify-center">
              <Typography
                label="CONGRATULATIONS"
                variant="f3"
                align="center"
                color={colorTheme.dark}
              />
            </div>
            <div className="pt-1">
              <Typography
                label="Thank you for taking the personality test. You can now see the matching percentage tags from other users who have taken the test!"
                variant="f1"
                align="center"
                color={colorTheme.darkFaded}
              />
            </div>
            <div className='flex justify-center pt-9'>
              <img src={vector} alt='vector' />
            </div>
          </div>
          <div className='fixed bottom-6 left-6 right-6 lg:relative lg:w-full lg:left-0 lg:bottom-0'>
            <div>
              <Button
                label="Close"
                variant="outlined"
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
export default PersonalityTestSuccess;
