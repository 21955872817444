import {useEffect} from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {Button, Input} from '../index';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {durationSchema} from '../../../app/schema/admin.schema';
import {DurationContent} from '../../../app/server/types/admin-control.types';

export type DurationInputProps = {
  duration?: DurationContent;
  timeOnly?: boolean;
  minuteSeconds?: boolean;
  isLoading?: boolean;
  onSubmit?: (value: DurationContent) => void;
};

const DurationInput = ({
  onSubmit,
  timeOnly,
  minuteSeconds,
  duration,
  isLoading,
}: DurationInputProps) => {
  const {
    control,
    getValues,
    reset,
    formState: {errors},
    handleSubmit,
  } = useForm<DurationContent>({
    resolver: yupResolver(durationSchema),
    defaultValues: {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
  });

  useEffect(() => {
    if (duration) {
      reset(duration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration]);

  const submitForm = () => {
    handleSubmit(submit)();
  };

  const submit = () => {
    if (onSubmit) {
      onSubmit(getValues());
    }
  };

  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-row space-x-3">
        {!timeOnly && !minuteSeconds && (
          <div style={{width: '100px'}}>
            <Controller
              control={control}
              name="days"
              render={({field: {value, onBlur, onChange}}) => (
                <Input
                  label="Days"
                  type="number"
                  inputSize="large"
                  placeholder="0d"
                  onChange={onChange}
                  value={
                    typeof value === 'number'
                      ? value
                      : isNaN(parseFloat(value as string))
                      ? ''
                      : parseFloat(value as string)
                  }
                  error={errors.days?.message}
                  onBlur={onBlur}
                />
              )}
            />
          </div>
        )}

        {!minuteSeconds && (
          <div style={{width: '100px'}}>
            <Controller
              control={control}
              name="hours"
              render={({field: {value, onBlur, onChange}}) => (
                <Input
                  label="Hours"
                  type="number"
                  inputSize="large"
                  placeholder="0h"
                  onChange={onChange}
                  value={
                    typeof value === 'number'
                      ? value
                      : isNaN(parseFloat(value as string))
                      ? ''
                      : parseFloat(value as string)
                  }
                  error={errors.hours?.message}
                  onBlur={onBlur}
                />
              )}
            />
          </div>
        )}

        <div style={{width: '100px'}}>
          <Controller
            control={control}
            name="minutes"
            render={({field: {value, onBlur, onChange}}) => (
              <Input
                label="Minutes"
                type="number"
                inputSize="large"
                placeholder="0m"
                onChange={onChange}
                value={
                  typeof value === 'number'
                    ? value
                    : isNaN(parseFloat(value as string))
                    ? ''
                    : parseFloat(value as string)
                }
                error={errors.minutes?.message}
                onBlur={onBlur}
              />
            )}
          />
        </div>

        {minuteSeconds && (
          <div style={{width: '100px'}}>
            <Controller
              control={control}
              name="seconds"
              render={({field: {value, onBlur, onChange}}) => (
                <Input
                  label="Seconds"
                  type="number"
                  inputSize="large"
                  placeholder="0s"
                  onChange={onChange}
                  value={
                    typeof value === 'number'
                      ? value
                      : isNaN(parseFloat(value as string))
                      ? ''
                      : parseFloat(value as string)
                  }
                  error={errors.seconds?.message}
                  onBlur={onBlur}
                />
              )}
            />
          </div>
        )}
      </div>
      <div>
        <Button
          label="Save"
          color="primary"
          onClick={submitForm}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default DurationInput;
