export enum RoutePath {
  // auth
  LOGIN = '/login',
  AUTH_REDIRECT = '/auth-redirect',
  EMPLOYER_SIGN_UP = '/employer-sign-up',
  SIGN_UP = '/sign-up',
  SIGN_IN = '/sign-in',
  PASSWORD_RESET = '/password-reset',

  // landing
  LANDING = '/landing',
  DISABLED_LANDING = '/disabled/landing',
  MATCHES_LANDING = '/matches',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  ABOUT_US = '/about-us',
  LATEST_NEWS = '/latest-news',
  PRIVACY_POLICY = '/privacy-policy',
  NOTIFICATIONS = '/notifications',

  //Settings
  SETTINGS = '/settings',
  LANGUAGE_SETTINGS = '/settings/language',
  SUBSCRIPTION_DETAILS = '/settings/subscription',
  NOTIFICATION_SETTINGS = '/settings/notification',
  SUBSCRIPTION_HISTORY = '/settings/subscription/history',

  // search
  FINDER_SETTINGS = '/finder-settings',
  DEFAULT_FINDER_SETTINGS = '/default-finder-settings',
  KAZAM_FILTER_SETTINGS = '/filter-settings',

  // profile
  MY_PROFILE = '/profile',
  MY_PROFILE_KZ = '/profile/kasambahay',
  MY_PROFILE_HO = '/profile/homeowner',
  EDIT_MY_PROFILE = '/edit-profile',
  EDIT_MY_PROFILE_KZ = '/edit-profile/kasambahay',
  EDIT_MY_PROFILE_HO = '/edit-profile/homeowner',

  PROFILE_SETUP = '/profile-setup',
  EDIT_PROFILE_SETUP = '/edit-profile-setup',

  //account
  DISABLE_ACCOUNT = '/account/disable',
  DELETE_ACCOUNT = '/account/delete',
  CHANGE_ACCOUNT_PASSWORD = '/account/change-password',

  // job seeker
  JOB_SEEKER_PROFILE_VIEW = '/job-seeker-profile/:id',

  // employer
  EMPLOYER_PROFILE_VIEW = '/employer-profile/:id',

  // favorite view
  FAVORITE_VIEW = '/favorite-view/:id',

  // logout
  LOGOUT = '/logout',

  // chat
  CHAT = '/chat',
  FAVORITES = '/favorites',
  KAWAYS = '/kaways',
  CHAT_ROOM = '/chat-room/:id',
  CHAT_FILTER = '/chat-filter/:id',

  //history
  HISTORY = '/history',


  // current location
  SAVE_CURRENT_LOCATION = '/save/current-location',
  SELECT_CURRENT_LOCATION = '/select/current-location',
  CURRENT_LOCATION = '/current-location/:id',
  VIEW_CURRENT_LOCATION = '/view/current-location/:id',

  // admin
  ADMIN = '/admin',
  ADMIN_BROADCAST = '/admin/broadcast',
  ADMIN_LANDING = '/admin/landing',
  ADMIN_SIGN_IN = '/admin/sign-in',
  ADMIN_CONTROLS = '/admin/controls',
  ADMIN_CHATS = '/admin/chats',
  ADMIN_CHAT_ROOM = '/admin/chat-room/:id',
  ADMIN_CHAT_PROFILE = '/admin/chat-profile/:id',
  ADMIN_USERS_ALL = '/admin/users/all',
  ADMIN_USERS_REPORTED = '/admin/users/reported',
  ADMIN_USERS_BANNED = '/admin/users/banned',
  ADMIN_USERS_SUSPENDED = '/admin/users/suspended',
  ADMIN_USERS_FOR_VERIFICATION = '/admin/users/for-verification',
  ADMIN_USERS_VERIFIED = '/admin/users/verified',
  ADMIN_ADS = '/admin/ads',
  ADMIN_REFERRALS_VIEW = '/admin/referrals/view/:id',
  ADMIN_REFERRALS_BY_USER = '/admin/referrals/users',
  ADMIN_GENERATED_REFERRALS = '/admin/referrals/admin',
  ADMIN_REPORT_VIEW = '/admin/report/view/:id',
  ADMIN_GENERATED_REFERRALS_VIEW = '/admin/generated/referrals/view/:id',
  ADMIN_SUBSCRIPTION = '/admin/subscription',
  ADMIN_VOUCHER = '/admin/voucher',

  REFERRAL_LANDING = '/referral/:id',

  SUCCESSFUL_PAYMENT_REDIRECTION = '/payment/success/:id',

  GET_VERIFIED = '/verification',

  VIDEO_CALL_ROOM = '/video-call-room/:id',
  VOICE_CALL_ROOM = '/voice-call-room/:id',

  //paywall
  PURCHASE_SUMMARY = '/purchase-summary/:id/:userId',

  //personality test
  TAKE_PERSONALITY_TEST = '/take-personality-test',
  PERSONALITY_TEST = '/personality-test',
  PERSONALITY_TEST_SUCCESS = '/personality-test/success'
}
