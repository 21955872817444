import {useMutation, useQueryClient} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';
import {QueryKeys} from './config/QueryConfig';
import {
  PostIsMobileExisting,
  PostPasswordResetRequest,
  PostUserBody,
  PostVerifyOtp,
  PutPasswordResetRequest,
} from '../types';

const usePostUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [QueryKeys.POST_USER],
    (data: PostUserBody) => API.post(ApiEndpointsEnum.POST_SIGN_UP, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.POST_USER]);
      },
    },
  );
};

const usePostOtpRequest = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [QueryKeys.POST_OTP_REQUEST],
    (data: PostIsMobileExisting) =>
      API.post(ApiEndpointsEnum.POST_OTP_REQUEST, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.POST_OTP_REQUEST]);
      },
    },
  );
};

const usePostPasswordResetRequest = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [QueryKeys.POST_PASSWORD_RESET_REQUEST],
    (data: PostPasswordResetRequest) =>
      API.post(ApiEndpointsEnum.POST_PASSWORD_RESET_REQUEST, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.POST_PASSWORD_RESET_REQUEST]);
      },
    },
  );
};

const usePostOtpVerify = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [QueryKeys.POST_OTP_VERIFY],
    (data: PostVerifyOtp) => API.post(ApiEndpointsEnum.POST_OTP_VERIFY, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.POST_OTP_VERIFY]);
      },
    },
  );
};

const usePostPasswordResetVerify = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [QueryKeys.POST_PASSWORD_RESET_VERIFY],
    (data: PostVerifyOtp) =>
      API.post(ApiEndpointsEnum.POST_PASSWORD_RESET_VERIFY, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.POST_PASSWORD_RESET_VERIFY]);
      },
    },
  );
};

const usePutPasswordReset = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [QueryKeys.PUT_PASSWORD_RESET],
    (data: PutPasswordResetRequest) =>
      API.put(ApiEndpointsEnum.PUT_PASSWORD_RESET, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.PUT_PASSWORD_RESET]);
      },
    },
  );
};

const usePutRecoverAccount = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [QueryKeys.PUT_RECOVER_ACCOUNT],
    (data: PostIsMobileExisting) =>
      API.put(ApiEndpointsEnum.PUT_RECOVER_ACCOUNT, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.PUT_RECOVER_ACCOUNT]);
      },
    },
  );
};

const useIsMobileExisting = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [QueryKeys.IS_MOBILE_EXISTING],
    (data: PostIsMobileExisting) =>
      API.post(ApiEndpointsEnum.IS_MOBILE_EXISTING, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.IS_MOBILE_EXISTING]);
      },
    },
  );
};

const usePatchVerificationRequest = () => {
  const queryClient = useQueryClient();

  return useMutation(
    [QueryKeys.PATCH_VERIFCATION_REQUEST],
    () =>
      API.patch(ApiEndpointsEnum.PATCH_VERIFICATION_REQUEST),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.PATCH_VERIFCATION_REQUEST]);
      },
    },
  );
};

export {
  usePostUser,
  useIsMobileExisting,
  usePostOtpRequest,
  usePostOtpVerify,
  usePostPasswordResetRequest,
  usePostPasswordResetVerify,
  usePutPasswordReset,
  usePutRecoverAccount,
  usePatchVerificationRequest
};
